var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { span: 16 } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.customerTaxType.label) } },
            [
              _c("SelectCustomerTax", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "customerTaxType",
                      {
                        initialValue: "",
                        rules: [
                          {
                            required: !_vm.isTaxNone,
                            message: _vm.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "[\n          'customerTaxType',\n          {\n            initialValue: '',\n            rules: [\n              {\n                required: !isTaxNone,\n                message: $t('lbl_validation_required_error'),\n              },\n            ],\n          },\n        ]"
                  }
                ],
                attrs: { disabled: _vm.disabled.customerTax },
                on: { change: _vm.changeCustomerTax }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.taxRegistrationNumber.label)
              }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.taxRegistrationNumber.decorator,
                    expression: "formRules.taxRegistrationNumber.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.taxRegistrationNumber.name,
                  placeholder: _vm.$t(
                    _vm.formRules.taxRegistrationNumber.placeholder
                  ),
                  autocomplete: "off",
                  disabled: _vm.disabled.taxRegistrationNumber
                },
                on: {
                  blur: function($event) {
                    return _vm.onBlur($event, "taxRegistrationNumber")
                  },
                  focus: function($event) {
                    return _vm.onFocus($event, "taxRegistrationNumber")
                  }
                }
              }),
              _c("ul", { attrs: { slot: "help" }, slot: "help" }, [
                _c("li", [_vm._v(_vm._s(_vm.$t("lbl_password_rule_2")))]),
                _c("li", [_vm._v("15 character")])
              ])
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: { label: _vm.$t(_vm.formRules.taxRegistrationName.label) }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.taxRegistrationName.decorator,
                    expression: "formRules.taxRegistrationName.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.taxRegistrationName.name,
                  placeholder: _vm.$t(
                    _vm.formRules.taxRegistrationName.placeholder
                  ),
                  autocomplete: "off",
                  disabled: _vm.disabled.taxRegistrationName
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.taxInvoiceHasBeenUploaded.label)
              }
            },
            [
              _c("a-checkbox", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.taxInvoiceHasBeenUploaded.decorator,
                    expression: "formRules.taxInvoiceHasBeenUploaded.decorator"
                  }
                ],
                attrs: { disabled: _vm.disabled.taxInvoiceHasBeenUploaded },
                on: { change: _vm.changeCheckbox }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.taxInvoiceDate.label) } },
            [
              _c("a-date-picker", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "taxInvoiceDate",
                      {
                        rules: [
                          {
                            required: !_vm.isTaxNone,
                            message: _vm.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "[\n          'taxInvoiceDate',\n          {\n            rules: [\n              {\n                required: !isTaxNone,\n                message: $t('lbl_validation_required_error'),\n              },\n            ],\n          },\n        ]"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.taxInvoiceDate.name,
                  placeholder: _vm.$t(_vm.formRules.taxInvoiceDate.placeholder),
                  format: _vm.DEFAULT_DATE_FORMAT,
                  disabled: true
                },
                on: {
                  blur: function(value) {
                    return _vm.onchangeFormMandatory()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.taxInvoiceNumber.label) } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.taxInvoiceNumber.decorator,
                    expression: "formRules.taxInvoiceNumber.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.taxInvoiceNumber.name,
                  placeholder: _vm.$t(
                    _vm.formRules.taxInvoiceNumber.placeholder
                  ),
                  autocomplete: "off",
                  disabled: true
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }