
























































































import { Component, Vue } from "vue-property-decorator";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";

@Component({
  methods: {
    formatterNumber,
    reverseFormatNumber,
  },
})
export default class InvoiceArStatus extends Vue {
  formRules = {
    total: {
      label: "lbl_total",
      name: "total",
      placeholder: "lbl_total",
      decorator: ["total"],
    },
    prepaymentUsed: {
      label: "lbl_prepayment_used",
      name: "prepaymentUsed",
      placeholder: "lbl_prepayment_used",
      decorator: ["prepaymentUsed"],
    },
    remainingInvoiceAmount: {
      label: "lbl_remaining_invoice_amount",
      name: "remainingInvoiceAmount",
      placeholder: "lbl_remaining_invoice_amount",
      decorator: ["remainingInvoiceAmount"],
    },
    creditMemo: {
      label: "lbl_credit_memo",
      name: "creditMemo",
      placeholder: "lbl_credit_memo",
      decorator: ["creditMemo"],
    },
    return: {
      label: "lbl_returns",
      name: "return",
      placeholder: "lbl_returns",
      decorator: ["return"],
    },
    accountReceivables: {
      label: "lbl_account_receivable",
      name: "accountReceivables",
      placeholder: "lbl_account_receivable",
      decorator: ["accountReceivables"],
    },
    paidStatus: {
      label: "lbl_paid_status",
      name: "paidStatus",
      placeholder: "lbl_paid_status",
      decorator: ["paidStatus"],
    },
    joinInvoice: {
      label: "lbl_join_invoice",
      name: "joinInvoice",
      placeholder: "lbl_join_invoice",
      decorator: ["joinInvoice"],
    },
  };

  emitView(name: string): void {
    this.$emit("on-view", { name, visible: true });
  }
}
