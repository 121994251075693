var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.disableButton.addRow
                  },
                  on: { click: _vm.addRow }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_add_row")))]
              ),
              _c(
                "a-button",
                {
                  attrs: {
                    type: "danger",
                    disabled: _vm.disableButton.deleteRow
                  },
                  on: { click: _vm.showConfirmation }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_delete_row")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c("TableCustom", {
            attrs: {
              idtable: "table1",
              dataSource: _vm.dataSourceDetailsTabpane,
              columns: _vm.columnsTable,
              scroll: { x: "calc(100% + 2500px)" },
              paginationcustom: false,
              defaultPagination: {
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", { total: total })
                }
              },
              getCheckboxProps: _vm.getCheckboxPropsDetail,
              handleSearchSelectTable: _vm.handleSearchSelectTable,
              selectedRowKeys: _vm.selectedRowKeysDetails,
              onSelectChange: _vm.onSelectChangeDetails,
              handleSelect: _vm.handleSelectTableDetails,
              handleInput: _vm.handleInputDetails,
              handleSwitch: _vm.handleSwitchDetails,
              handleInputNumber: _vm.handleInputNumberDetails
            },
            on: { "on-view-more": _vm.onViewMore }
          })
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 18, align: "right" } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.total.label) } },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.total.decorator,
                    expression: "formRules.total.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.total.name,
                  placeholder: _vm.$t(_vm.formRules.total.placeholder),
                  autocomplete: "off",
                  disabled: true,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  min: 0,
                  precision: _vm.storeBaseDecimalPlace
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t("lbl_discount") } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 0] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { staticClass: "mb-0 pb-0" },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRules.discountAmountPercent.decorator,
                                expression:
                                  "formRules.discountAmountPercent.decorator"
                              }
                            ],
                            staticClass: "w-100",
                            attrs: {
                              name: _vm.formRules.discountAmountPercent.name,
                              placeholder: _vm.$t(
                                _vm.formRules.discountAmountPercent.placeholder
                              ),
                              autocomplete: "off",
                              disabled: _vm.disabled.discountAmountPercent,
                              formatter: _vm.formatterPercent,
                              parser: _vm.reverseFormatPercent,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace
                            },
                            on: {
                              change: function(value) {
                                return _vm.changeDataInput(
                                  value || 0,
                                  "additionalDiscountPercent"
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { staticClass: "mb-0 pb-0" },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRules.discountAmountValue.decorator,
                                expression:
                                  "formRules.discountAmountValue.decorator"
                              }
                            ],
                            staticClass: "w-100",
                            attrs: {
                              name: _vm.formRules.discountAmountValue.name,
                              placeholder: _vm.$t(
                                _vm.formRules.discountAmountValue.placeholder
                              ),
                              autocomplete: "off",
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: _vm.disabled.discountAmountValue
                            },
                            on: {
                              change: function(value) {
                                return _vm.changeDataInput(
                                  value || 0,
                                  "additionalDiscountAmount"
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.totalTax.label) } },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.totalTax.decorator,
                    expression: "formRules.totalTax.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.totalTax.name,
                  placeholder: _vm.$t(_vm.formRules.totalTax.placeholder),
                  autocomplete: "off",
                  disabled: true,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  min: 0,
                  precision: _vm.storeBaseDecimalPlace
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.totalPrepayment.label) } },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.totalPrepayment.decorator,
                    expression: "formRules.totalPrepayment.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.totalPrepayment.name,
                  placeholder: _vm.$t(
                    _vm.formRules.totalPrepayment.placeholder
                  ),
                  disabled: "",
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  min: 0,
                  precision: _vm.storeBaseDecimalPlace
                }
              })
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.grandTotal.label) } },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.grandTotal.decorator,
                    expression: "formRules.grandTotal.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.grandTotal.name,
                  placeholder: _vm.$t(_vm.formRules.grandTotal.placeholder),
                  disabled: true,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  min: 0,
                  precision: _vm.storeBaseDecimalPlace
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }