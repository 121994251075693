var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 17 } },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "danger" },
                  on: { click: _vm.reset }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.storePrepayment.length,
                      expression: "!storePrepayment.length"
                    }
                  ]
                },
                [_vm._v(_vm._s(_vm.$t("lbl_no_document_selected")))]
              ),
              _vm._l(_vm.storePrepayment, function(doc) {
                return _c(
                  "a-button",
                  {
                    key: doc.key,
                    staticClass: "mr-2",
                    attrs: { type: "dashed" },
                    on: {
                      click: function($event) {
                        return _vm.deletePrepayment(
                          doc.invoicePrepaymentNumberId
                        )
                      }
                    }
                  },
                  [
                    _c("a-icon", {
                      attrs: { type: "delete", theme: "filled" }
                    }),
                    _vm._v(
                      " " + _vm._s(doc.invoicePrepaymentNumber || "-") + " "
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "a-col",
            { attrs: { span: 7, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    loading: _vm.loading.prepaymentRefresh,
                    icon: "reload"
                  },
                  on: { click: _vm.handleRefresh }
                },
                [_vm._v(_vm._s(_vm.$t("lbl_refresh")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                staticStyle: { "margin-top": "4px" },
                attrs: {
                  idtable: "table1",
                  dataSource: _vm.dataSourceApplyPrepaymentTabpane.data,
                  columns: _vm.columnsTable,
                  selectedRowKeys: null,
                  onSelectChange: null,
                  getCheckboxProps: null,
                  scroll: { x: "calc(700px + 50%)" },
                  paginationcustom: false,
                  defaultPagination: {
                    pageSize: _vm.pageSize,
                    total: _vm.dataSourceApplyPrepaymentTabpane.totalElements
                  },
                  loading: _vm.loading.prepaymentTable
                },
                on: {
                  "on-tablechange": _vm.onTableChange,
                  "on-customData": _vm.onSelectChangeApplyPrepayment
                },
                scopedSlots: _vm._u([
                  {
                    key: "custom-footer",
                    fn: function() {
                      return [
                        _c(
                          "table",
                          {
                            staticStyle: {
                              width: "400px",
                              "min-width": "unset"
                            },
                            attrs: {
                              "aria-describedby": "tableTotalPrepayment"
                            }
                          },
                          [
                            _c("thead", [
                              _c("th", { attrs: { colspan: "2" } })
                            ]),
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("lbl_total_prepayment")))
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.calcSumPrepayment.raw
                                      )
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "my-4", attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-tag",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { color: "grey" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lbl_total_found")) +
                      ": " +
                      _vm._s(
                        _vm.dataSourceApplyPrepaymentTabpane.totalElements
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }