
















































































import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";
const Props = Vue.extend({
  props: [
    "dataSourceApplyPrepaymentTabpane",
    "onSelectChangeApplyPrepayment",
    "reset",
    "pageSize",
    "loading",
  ],
});
@Component({
  computed: {
    ...mapState({
      storePrepayment: (store: any) => store.invoiceStore.tabPrepayment,
    }),
    ...mapGetters({
      calcSumPrepayment: "invoiceStore/CALC_SUM_PREPAYMENT",
    }),
  },
  methods: {
    ...mapMutations({
      setPrepayment: "invoiceStore/SET_PREPAYMENT",
    }),
  },
})
export default class InvoiceArPrepayment extends Props {
  columnsTable = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 110,
      scopedSlots: { customRender: "isNull" },
      responsiveColSelect: [
        {
          name: "invoicePrepaymentNumber",
          placeholder: "Invoice Prepayment Number",
          value: "id",
          options: [] as [],
          style: "width: 100%",
        },
      ],
      responsiveColInput: [
        {
          name: "description",
          placeholder: "Description",
          style: "width: 100%;",
        },
      ],
    },
    {
      title: "Invoice Prepayment Number",
      dataIndex: "invoicePrepaymentNumber",
      key: "invoicePrepaymentNumber",
      width: 200,
      scopedSlots: { customRender: "invoicePrepaymentNumber" },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 150,
      scopedSlots: { customRender: "description" },
    },
    {
      title: "",
      dataIndex: "add",
      key: "add",
      width: 200,
      scopedSlots: { customRender: "buttonCustom" },
    },
  ] as ColumnTableCustom[];

  onTableChange({
    pagination,
  }: {
    pagination: { pageSize: number; total: number; current: number };
  }): void {
    this.$emit("change", { pagination });
  }

  deletePrepayment(prepaymentId: string): void {
    const newPrepayments = this.storePrepayment.filter(
      doc => doc.invoicePrepaymentNumberId !== prepaymentId
    );
    this.setPrepayment([...newPrepayments]);
    this.$emit("delete");
  }

  handleRefresh(): void {
    this.$emit("on-refresh");
  }
}
