






















































































import { SelectCustomerTax } from "@/components/InvoiceAr";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { checkTaxNumber } from "@/validator/globalvalidator";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

const Props = Vue.extend({
  props: [
    "changeCustomerTax",
    "loading",
    "disabled",
    "changeCheckbox",
    "onchangeFormMandatory",
    "onBlur",
    "onFocus",
  ],
});
@Component({
  components: { SelectCustomerTax },
  computed: {
    ...mapGetters({
      isTaxNone: "invoiceStore/TAX_CALCULATION_NONE",
    }),
  },
})
export default class InvoiceArTax extends Props {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  isTaxNone!: boolean;

  formRules = {
    customerTaxType: {
      label: "lbl_customer_tax_type",
      name: "customerTaxType",
      placeholder: "lbl_customer_tax_type",
    },
    taxRegistrationNumber: {
      label: "lbl_tax_registration_number",
      name: "taxRegistrationNumber",
      placeholder: "lbl_tax_registration_number",
      decorator: [
        "taxRegistrationNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
            {
              validator: checkTaxNumber,
            },
          ],
        },
      ],
    },
    taxRegistrationName: {
      label: "lbl_tax_registration_name",
      name: "taxRegistrationName",
      placeholder: "lbl_tax_registration_name",
      decorator: [
        "taxRegistrationName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxInvoiceHasBeenUploaded: {
      label: "lbl_tax_invoice_uploaded",
      name: "taxInvoiceHasBeenUploaded",
      placeholder: "lbl_uploaded",
      decorator: [
        "taxInvoiceHasBeenUploaded",
        {
          valuePropName: "checked",
          rules: [
            {
              type: "boolean",
            },
          ],
        },
      ],
    },
    taxInvoiceDate: {
      label: "lbl_tax_invoice_date",
      name: "taxInvoiceDate",
      placeholder: "lbl_tax_invoice_date",
    },
    taxInvoiceNumber: {
      label: "lbl_tax_invoice_number",
      name: "taxInvoiceNumber",
      placeholder: "lbl_tax_invoice_number",
      decorator: ["taxInvoiceNumber"],
    },
  };
}
