































































































































































































































































































































































































































































































































































































































































































































































































































































import CurrencySelect from "@/components/custom/select/CurrencySelect.vue";
import SelectEmployee from "@/components/custom/select/EmployeeSelect.vue";
import SelectTaxCalculation from "@/components/custom/select/SelectTaxCalculation.vue";
import InvoicePrintModal from "@/components/InvoiceAr/InvoicePrintModal.vue";
import {
  isNotUnique,
  removeDuplicate,
  setNumbering,
  toTitlecase,
} from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { Row, useContactData, useTaxRate } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { StorageKeys } from "@/models/constant/enums/storage.enum";
import { ONE } from "@/models/constant/global.constant";
import { OptionModel } from "@/models/constant/interface/common.interface";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { INVOICE_SOURCE } from "@/models/enums/invoice.enum";
import { Messages } from "@/models/enums/messages.enum";
import { PREFERENCE_FEATURE_KEY } from "@/models/enums/preference.enum";
import { PREPAYMENT_STATUS } from "@/models/enums/prepayment.enum";
import { PRODUCT_TYPE } from "@/models/enums/product.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { DetailContactDataDto } from "@/models/interface/contact-data";
import {
  ContactData,
  CustomAddressDataList,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { DataDetailDeliveryOrder } from "@/models/interface/deliveryorder.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  InvoiceIcBillingLineResponseDto,
  InvoiceIcBillingResponseDto,
} from "@/models/interface/ic-billing";
import {
  InvoiceARCreditLineResponseDTO,
  InvoiceARLine,
  InvoiceARLines,
  InvoiceARReceiptDetail,
  PostInvoice,
  PrepaymentLine,
  ResponseDetailInvoiceAR,
} from "@/models/interface/invoice.interface";
import { InvoicePrepayment } from "@/models/interface/invoicePrepayment";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { IPreferencesResponseDto } from "@/models/interface/preference";
import { IProductResponse } from "@/models/interface/product.interface";
import { SalesOrderResponseDto } from "@/models/interface/sales-order";
import {
  DataCoa,
  ResponseListOfCoa,
} from "@/models/interface/settings.interface";
import { PropsModel } from "@/models/interfaces/common.interface";
import { contactServices } from "@/services/contact.service";
import { deliveryOrderServices } from "@/services/deliveryorder.service";
import { invoiceServices } from "@/services/invoice.service";
import { invoicePrepayment } from "@/services/invoicePrepayment";
import LocalStorageService from "@/services/LocalStorage.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { productService } from "@/services/product.service";
import { salesOrderServices } from "@/services/salesorder.service";
import { settingsServices } from "@/services/settings.service";
import { State as InvoiceStore } from "@/store/invoice.store";
import { LabelInValue } from "@/types";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  formatTaxNumber,
  formatTaxNumbertoNumber,
  formatterNumber,
  formatterPercent,
  reverseFormatNumber,
  reverseFormatPercent,
} from "@/validator/globalvalidator";
import { ListContactDataDto } from "@interface/contact-data";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Decimal } from "decimal.js-light";
import moment, { Moment } from "moment";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import TabDetails from "./InvoiceArDetails.vue";
import TabPrepayment from "./InvoiceArPrepayment.vue";
import TabStatus from "./InvoiceArStatus.vue";
import TabTax from "./InvoiceArTax.vue";

enum TYPE_SOURCE {
  RECURRING = "recurring",
  INVOICES = "invoices",
}

enum STATUS {
  NEED_APPROVAL = "Need Approval",
  DRAFT = "Draft",
  REJECTED = "Rejected",
  CANCELLED = "Cancelled",
  POSTED = "Posted",
  DELIVERED = "Delivered",
  UNPAID = "Unpaid",
  PARTIALPAID = "Partial Paid",
  FULLYPAID = "Fully Paid",
  RETURNED = "Returned",
}
export interface Dropdown {
  key?: number;
  name?: string;
  id?: string;
  available?: number;
}
export interface TableDataAplyPrepayment {
  no?: string;
  invoicePrepaymentNumber: string;
  invoicePrepaymentNumberId: string;
  amount: string;
  description: string;
  remainingAmount?: string;
  key?: number;
  disabledInput?: boolean;
  disabledSelect?: boolean;
  id?: string;
  disableByRow?: any;
  add?: string; // label button custom
}
export interface TableDataDetails {
  no: string;
  baseAmount: number | string;
  description: string;
  incomeAccountTaxId: string;
  merk: string;
  productId: string;
  productCode: string;
  percentDiscount: number | string;
  discountValue: number | string;
  billingPeriodModal?: string;
  includePPh: boolean;
  productName: string;
  qty: number | string;
  price: number | string;
  documentReference: string;
  revenueAccountId: string;
  revenueAccount: string;
  salesOrderLineId?: string;
  deliveryOrderLineId?: string;
  subTotal: number | string;
  taxValue: number | string;
  uomId: string;
  uom: string;
  location: string;
  key: number;
  id?: string;
  optionCustom?: OptionModel[];
  taxCode?: LabelInValue;
  taxRate?: number;
  incomeAccountTaxRatePercent?: number | string;
  incomeAccountTaxRateValue?: number | string;
  disabledInput?: boolean;
  disabledSelect?: boolean;
  invoiceICBillingLineId?: null;
  internalContractBillingId?: string;
  disableByRow?: any;
  grossAfterDiscount?: number;
  productType?: string;
  proRateRatio?: number;
  proRateAdditionalDiscountAmount?: number;
  amountAfterProRateAdditionalDiscount?: number;
  proRateAmountAfterAdditionalDiscountRatio?: number;
  proRatePrepaymentAmount?: number;
  salesId: string;
  salesName?: string;
  customerLocation: string;
  documentReferenceId: string;
}
export interface StatusTabpane {
  total: string | number;
  prepaymentUsed: string | number;
  remainingInvoiceAmount: string | number;
  creditMemo: string | number;
  return: string | number;
  accountReceivables: string | number;
  paidStatus: string;
  joinInvoice: string | number;
}

export enum COMPONENT_TABPANE_NAME {
  INV_DETAILS = "INV_DETAILS",
  INV_TAX_DETAILS = "INV_TAX_DETAILS",
  INV_PREPAYMENT = "INV_PREPAYMENT",
  INV_STATUS = "INV_STATUS",
}
export interface PrepaymentLines {
  appliedAmount: number;
  description: string;
  invoicePrepaymentId: string;
  id?: string;
}

type Submit = "submit" | "back" | "cancel" | "update" | "reject";

/**
 * @description
 * ic billings detail row
 */
type RowIcBiling = Row<
  {
    internalContractNo: string;
    salesOrderNo: string;
    deliveryOrderNo: string;
    bastNo: string;
    billingNo: string;
    billingDate: string;
    rentPeriodDate: string;
  },
  number
>;

const ALLOW_APPLY_PREPAYMENT: string[] = [
  STATUS.DRAFT,
  STATUS.NEED_APPROVAL,
  STATUS.DELIVERED,
  STATUS.UNPAID,
];

const TYPE_SELECT_DO: INVOICE_SOURCE[] = [
  INVOICE_SOURCE.UNIT_SALE,
  INVOICE_SOURCE.PRODUCT_SALE,
  INVOICE_SOURCE.ASSET_SALE,
];

const TYPE_SELECT_SO: INVOICE_SOURCE[] = [INVOICE_SOURCE.INVOICE_OTHER];

const DISABLE_ROW: STATUS[] = [
  STATUS.REJECTED,
  STATUS.CANCELLED,
  STATUS.RETURNED,
];

const ALLOW_TO_CANCEL: STATUS[] = [STATUS.UNPAID];
const ALLOW_TO_REJECT: STATUS[] = [STATUS.NEED_APPROVAL];
const ALLOW_TO_POST_JOURNAL: STATUS[] = [STATUS.UNPAID];

@Component({
  components: {
    TabDetails,
    TabPrepayment,
    TabTax,
    TabStatus,
    SelectCurrency: CurrencySelect,
    SelectEmployee,
    InvoicePrintModal,
    SelectTaxCalculation,
  },
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  computed: {
    ...mapState({
      storeForm: (store: any) =>
        store.invoiceStore.form as InvoiceStore["form"],
      storePrepayment: (store: any) => store.invoiceStore.tabPrepayment,
      storeTableDetails: (store: any) => store.invoiceStore.tabDetailSource,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
      storePreferenceField: (st: any) => st.preferenceStore.field,
    }),
    ...mapGetters({
      calcSumPrepayment: "invoiceStore/CALC_SUM_PREPAYMENT",
      calcSumBaseAmount: "invoiceStore/CALC_SUM_BASE_AMOUNT",
      getPreferenceByKey: "preferenceStore/GET_PREFERENCE_BY_KEY",
      isTaxNone: "invoiceStore/TAX_CALCULATION_NONE",
    }),
  },
  methods: {
    formatterPercent,
    reverseFormatPercent,
    ...mapMutations({
      setDataSourceDetailsTabPane:
        "invoiceStore/SET_DATA_SOURCE_DETAILS_TAB_PANE",
      setForm: "invoiceStore/SET_FORM",
      setPrepayment: "invoiceStore/SET_PREPAYMENT",
    }),
    ...mapActions({
      calcPricingProduct: "invoiceStore/CALC_PRICING_INVOICE_PRODUCT",
      resetStore: "invoiceStore/RESET_STORE",
      dispatchAssigneeField: "preferenceStore/dispatchAssigneeField",
      disableTaxLines: "invoiceStore/disableTaxLines",
      enableTaxLines: "invoiceStore/enableTaxLines",
    }),
  },
})
export default class InvoiceArIndex extends Mixins(MNotificationVue) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  setForm!: (payload: InvoiceStore["form"]) => void;
  disableTaxLines!: () => void;
  enableTaxLines!: () => void;
  dispatchAssigneeField!: (contactId: string) => Promise<DetailContactDataDto>;
  storeForm!: InvoiceStore["form"];
  isTaxNone!: boolean;

  changeSource = 0 as number;
  dataDetailRecuring!: InvoiceIcBillingResponseDto;
  type = "" as "recurring" | "invoices";
  mode = "" as Mode;
  form!: WrappedFormUtils; // form header
  titleModal = "" as string;
  modalOpenDetail = false as boolean;
  dataSourceModalPrepayment: PrepaymentLines[] = [];
  dataSourceModalCreditMemo: InvoiceARCreditLineResponseDTO[] = [];
  dataSourceModalPaidStatus: InvoiceARReceiptDetail[] = [];
  dataSourceDetails: RowIcBiling[] = [];
  typeCurrency = "" as string;
  selectedRowKeysDetails = [] as number[];
  listDeletedIdDetails = [] as string[];
  disableButton = {
    addRow: false as boolean,
    deleteRow: false as boolean,
    generate: false as boolean,
    postJournal: false,
  };
  disabled = {
    grNumber: false as boolean,
    poNumber: false as boolean,
    rate: false as boolean,
    receivableAccount: false as boolean,
    discountPercent: false as boolean,
    discountValue: false as boolean,
    customerTax: false as boolean,
    taxRegistrationNumber: false as boolean,
    taxRegistrationName: false as boolean,
    invoiceType: false as boolean,
    source: false as boolean,
    branch: false as boolean,
    customerName: false as boolean,
    customerShipAddress: false as boolean,
    customerBillAddress: false as boolean,
    doNumber: false as boolean,
    salesOrder: false as boolean,
    taxType: false as boolean,
    termOfPayment: false as boolean,
    invoiceDate: false as boolean,
    currency: false as boolean,
    operatorName: false as boolean,
    usageType: false as boolean,
    invoiceDescription: false as boolean,
    print: true as boolean,
    discountAmountValue: false as boolean,
    discountAmountPercent: false as boolean,
    salesName: false as boolean,
    assignee: false,
  };
  dataPrepaymentLines = [] as PrepaymentLines[];
  columnsTableModalPrepayment = [
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoicePrepaymentDate",
      key: "invoiceDate",
      width: 120,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoicePrepaymentNo",
      key: "invoiceNumber",
      width: 250,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_prepayment_used_amount"),
      dataIndex: "appliedAmount",
      key: "prepaymentUsedAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
  ];
  columnsTableModalCreditMemo = [
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceCreditDate",
      key: "invoiceDate",
      width: 120,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceCreditNo",
      key: "invoiceNumber",
      width: 250,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_credit_amount"),
      dataIndex: "creditAmount",
      key: "creditAmount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
  ];
  columnsTableModalPaidStatus = [
    {
      title: this.$t("lbl_ar_receipt_date"),
      dataIndex: "receiptDate",
      key: "arReceiptDate",
      width: 120,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_ar_receipt_number"),
      dataIndex: "receiptNumber",
      key: "arReceiptNumber",
      width: 250,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "receiptAmount",
      key: "amount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
  ];
  columnsTableModalDetails = [
    {
      title: this.$t("lbl_ic_number"),
      dataIndex: "internalContractNo",
      key: "icNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_so_number"),
      dataIndex: "salesOrderNo",
      key: "soNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_do_number"),
      dataIndex: "deliveryOrderNo",
      key: "doNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_bast_number"),
      dataIndex: "bastNo",
      key: "bastNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_billing_number"),
      dataIndex: "billingNo",
      key: "billing",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_billing_period"),
      dataIndex: "billingDate",
      key: "billingDate",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_rent_period"),
      dataIndex: "rentPeriodDate",
      key: "rentPeriodDate",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
  ];
  activeTabPane = "Details" as string;
  listId = [] as string[];
  listPoNumber = [] as string[];
  dataBranch = [] as DataWarehouseBranch[];
  dataInvoiceType = [] as ResponseListMaster[];
  dataListSource = [] as ResponseListMaster[];
  dataCustomer = [] as ContactData[];
  columnTabPane = [] as string[];
  dataCustomerShipAddress = [] as CustomAddressDataList[];
  dataCustomerBillAddress = [] as CustomAddressDataList[];
  dataInvoiceNumber = [] as [];
  dataSalesOrder: OptionModel[] = [];
  dataDoNumber: OptionModel[] = [];
  dataTermOfPayment = [] as ResponseListMaster[];
  dataReceivableAccount = [] as DataCoa[];
  dicountNumberValue = "" as string;
  dicountNumberPercent = "" as string;
  formDetailModal!: WrappedFormUtils;
  formTable!: WrappedFormUtils; // form each tab pane
  modal = {
    row: {
      index: -1,
      data: {} as TableDataDetails,
      show: false,
    },
  };
  listDataTaxCode = [] as any;
  tempData = {
    shortLocation: "" as string,
  };
  loading = {
    postJournal: false,
    branch: false as boolean,
    salesOrder: false as boolean,
    doNumber: false as boolean,
    invoiceType: false as boolean,
    source: false as boolean,
    customer: false as boolean,
    sales: false as boolean,
    invoiceNumber: false as boolean,
    termOfPayment: false as boolean,
    currency: false as boolean,
    receivableAccount: false as boolean,
    print: false as boolean,
    incomeTax: false as boolean,
    customerTax: false as boolean,
    cancel: false as boolean,
    update: false as boolean,
    submit: false as boolean,
    reject: false as boolean,
    prepaymentRefresh: false,
    prepaymentTable: false,
  };
  valueRadio = "" as string;
  show = {
    so: false as boolean,
    do: false as boolean,
    submit: true as boolean,
    cancel: false as boolean,
    reject: false as boolean,
    print: false as boolean,
    update: false as boolean,
    details: false as boolean,
  };
  disabledTableColumn = {
    qty: false as boolean,
    price: false as boolean,
    documentPreference: false as boolean,
    productCode: false as boolean,
    productName: false as boolean,
    uom: false as boolean,
    revenueAccount: false as boolean,
  };
  typeDiscount = "" as string;
  dataSourceDetailsTabpane = [] as TableDataDetails[];
  dataSourceApplyPrepaymentTabpane = {
    data: [] as TableDataAplyPrepayment[],
    currentPage: 0,
    totalElements: 0,
    totalPages: 0,
  };
  detailDataInvoiceAr = {} as ResponseDetailInvoiceAR;
  dataGenerateTable = {
    type: "" as "so" | "do" | "recurring",
    listId: [] as string[],
  };
  formatTax = formatTaxNumber;
  formatTaxtoNumber = formatTaxNumbertoNumber;

  journalNumber = {
    name: "" as string,
    id: "" as string,
  };
  formRules = {
    assignee: {
      label: "lbl_assignee",
      name: "assignedBy",
      decorator: [
        "assignedBy",
        {
          initialValue: "",
          rules: [
            {
              required: true,
              message: this.$t("lbl_validation_required_error"),
            },
          ],
        },
      ],
    },
    salesOrder: {
      label: "lbl_sales_order",
      name: "salesOrder",
      placeholder: "lbl_sales_order",
      decorator: [
        "salesOrder",
        {
          initialValue: [],
          rules: [
            {
              type: "array",
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    doNumber: {
      label: "lbl_do_number",
      name: "doNumber",
      placeholder: "lbl_do_number",
      decorator: [
        "doNumber",
        {
          initialValue: [],
          rules: [
            {
              type: "array",
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch",
      decorator: [
        "branch",
        {
          initialValue: null,
          rules: [
            {
              type: "string",
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceType: {
      label: "lbl_invoice_type",
      name: "invoiceType",
      placeholder: "lbl_invoice_type",
      decorator: [
        "invoiceType",
        {
          initialValue: null,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    source: {
      label: "lbl_source",
      name: "source",
      placeholder: "lbl_source",
      decorator: [
        "source",
        {
          initialValue: null,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    details: {
      label: "lbl_details",
      name: "details",
      placeholder: "lbl_details",
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    grNumber: {
      label: "lbl_good_receipt_number",
      name: "grNumber",
      placeholder: "lbl_good_receipt_number_placeholder",
      decorator: ["grNumber"],
    },
    poNumber: {
      label: "lbl_purchase_order_number",
      name: "poNumber",
      placeholder: "lbl_purchase_order_number_placeholder",
      decorator: ["poNumber"],
    },
    customerShipAddress: {
      label: "lbl_customer_ship_address",
      name: "customerShipAddress",
      placeholder: "lbl_customer_ship_address",
      decorator: [
        "customerShipAddress",
        {
          initialValue: null,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerBillAddress: {
      label: "lbl_customer_bill_address",
      name: "customerBillAddress",
      placeholder: "lbl_customer_bill_address",
      decorator: [
        "customerBillAddress",
        {
          initialValue: null,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxCalculation: {
      label: "lbl_tax_calculation",
      name: "taxCalculation",
      placeholder: "lbl_tax_calculation",
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      name: "invoiceDate",
      placeholder: "lbl_invoice_date",
      decorator: [
        "invoiceDate",
        {
          initialValue: null,
          rules: [
            {
              type: "object",
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    accountingDate: {
      label: "lbl_accounting_date",
      name: "accountingDate",
      placeholder: "lbl_accounting_date",
      decorator: [
        "accountingDate",
        {
          initialValue: null,
          rules: [
            {
              type: "object",
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceReceiveDate: {
      label: "lbl_receive_date",
      name: "invoiceReceiveDate",
      placeholder: "lbl_receive_date",
      decorator: [
        "invoiceReceiveDate",
        {
          initialValue: null,
          rules: [
            {
              type: "object",
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency",
      decorator: [
        "currency",
        {
          initialValue: null,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rate: {
      label: "lbl_rate",
      name: "rate",
      placeholder: "lbl_rate",
      decorator: [
        "rate",
        {
          initialValue: null,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    journalNumber: {
      label: "lbl_journal_number",
      name: "journalNumber",
      placeholder: "lbl_journal_number",
    },
    operatorName: {
      label: "lbl_operator_name",
      name: "operatorName",
      placeholder: "lbl_operator_name",
      decorator: [
        "operatorName",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    usageType: {
      label: "lbl_usage_type",
      name: "usageType",
      placeholder: "lbl_usage_type",
      decorator: [
        "usageType",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceDescription: {
      label: "lbl_invoice_description",
      name: "invoiceDescription",
      placeholder: "lbl_invoice_description",
      decorator: [
        "invoiceDescription",
        {
          initialValue: null,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    statusInvoice: {
      label: "lbl_status_invoice",
      name: "statusInvoice",
      placeholder: "lbl_status_invoice",
      decorator: [
        "statusInvoice",
        {
          initialValue: null,
        },
      ],
    },
    receivableAccount: {
      label: "lbl_receivable_account",
      name: "receivableAccount",
      placeholder: "lbl_receivable_account",
      decorator: [
        "receivableAccount",
        {
          initialValue: null,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "invoiceNumber",
      placeholder: "lbl_invoice_number",
      decorator: [
        "invoiceNumber",
        {
          initialValue: null,
        },
      ],
    },
    termOfPayment: {
      label: "lbl_term_of_payments",
      name: "termOfPayment",
      placeholder: "lbl_term_of_payments",
      decorator: [
        "termOfPayment",
        {
          initialValue: null,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    productCode: {
      decorator: [
        "productCode",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    productName: {
      decorator: [
        "productName",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    productMerk: {
      decorator: [
        "productMerk",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    productQty: {
      decorator: [
        "productQty",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    productUom: {
      decorator: [
        "productUom",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    billingPeriodModal: {
      decorator: [
        "billingPeriodModal",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    price: {
      decorator: [
        "price",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    incomeTaxRatePercent: {
      decorator: [
        "incomeTaxRatePercent",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    incomeTaxRateValue: {
      decorator: [
        "incomeTaxRateValue",
        {
          initialValue: null,
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    baseAmount: {
      decorator: [
        "baseAmount",
        {
          initialValue: null,
        },
      ],
    },
  };
  queryParams = {
    invoicePrepayment: {
      limit: 10,
      page: 0,
      search: `invoicePrepaymentStatus~${PREPAYMENT_STATUS.UN_APPLIED}`,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel,
    receivableAccount: {
      limit: 10,
      page: 0,
      search: "parentAccount.code~110201*",
    } as RequestQueryParamsModel,
  };

  docId = "";
  modalPrint = {
    show: false,
    toggle(): void {
      this.show = !this.show;
    },
  };
  assigneeId = "";

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "invoiceDetail" });
    this.formDetailModal = this.$form.createForm(this, {
      name: "formDetailsModal",
    });
    this.formTable = this.$form.createForm(this, { name: "formTable" });

    const values = {
      invoiceType: undefined,
      source: undefined,
      branch: undefined,
      customerName: undefined,
      customerShipAddress: undefined,
      customerBillAddress: undefined,
      currency: undefined,
      rate: undefined,
      taxCalculation: undefined,
      termOfPayment: undefined,
      invoiceNumber: undefined,
      invoiceDate: undefined,
      accountingDate: undefined,
      invoiceReceiveDate: undefined,
      operatorName: undefined,
      invoiceDescription: undefined,
      statusInvoice: undefined,
      usageType: undefined,
      receivableAccount: undefined,
    };

    for (const key in values) {
      this.form.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }

    const valuesModal = {
      productCode: "",
      productName: "",
      productMerk: "",
      productQty: "",
      productUom: "",
      billingPeriodModal: "",
      price: "0",
      percentDiscount: 0,
      discountValue: 0,
      incomeTaxRatePercent: "0",
      incomeTaxRateValue: "0",
      baseAmount: "0",
      includePPh: false,
    };

    for (const key in valuesModal) {
      this.formDetailModal.getFieldDecorator(key, {
        initialValue: valuesModal[key],
      });
    }
  }

  created(): void {
    this.searchReceivableAccount = debounceProcess(
      this.searchReceivableAccount
    );

    this.type = this.$route.meta.type;
    this.mode = this.$route.meta.mode;
    this.docId = this.$route.params.id;
  }

  mounted(): void {
    this.getInvoiceType();
    this.getSource();
    this.getBranch("");
    this.getCustomerName("");
    this.getTermOfPayment();
    this.getListReceivableAccount(this.queryParams.receivableAccount);

    if (this.mode === Mode.CREATE) {
      this.setDefaultFieldValue();
      this.columnTabPane = ["Details", "Tax Details", "Apply Prepayment"];

      if (this.isRecurring && this.$route.query.recurringIds) {
        this.show.details = true;
        this.show.do = false;
        this.show.so = false;
        this.getCreateInvoiceRecuring(
          this.$route.query.recurringIds as string[]
        );
      } else {
        this.show.details = false;
      }
    } else {
      this.columnTabPane = [
        "Details",
        "Tax Details",
        "Apply Prepayment",
        "Status",
      ];

      this.getDetailInvoiceAr();
    }
  }

  get isModeCreate(): boolean {
    return this.mode === Mode.CREATE;
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  get isRecurring(): boolean {
    return this.type === "recurring";
  }
  get isShowBillingPeriod(): boolean {
    return (
      !!this.detailDataInvoiceAr.invoiceSource &&
      this.detailDataInvoiceAr.invoiceSource === INVOICE_SOURCE.RECURING_INVOICE
    );
  }
  get allowCancel(): boolean {
    return ALLOW_TO_CANCEL.includes(this.detailDataInvoiceAr?.status as STATUS);
  }

  get allowPostJournal(): boolean {
    return (
      ALLOW_TO_POST_JOURNAL.includes(
        this.detailDataInvoiceAr?.status as STATUS
      ) && !this.detailDataInvoiceAr?.journalId
    );
  }

  get allowReject(): boolean {
    return ALLOW_TO_REJECT.includes(this.detailDataInvoiceAr?.status as STATUS);
  }

  @Watch("dataSourceDetailsTabpane.length")
  onChangeInvoiceARLines(newValue: number, oldValue: number): void {
    if (newValue > oldValue) return;
    const lines = this.dataSourceDetailsTabpane;
    const { doNumber, salesOrder: soNumber } = this.form.getFieldsValue();
    const references = this.show.so ? soNumber : doNumber;
    let newLines: TableDataDetails[] = [];
    references.forEach((ref: string) => {
      newLines = [
        ...newLines,
        ...lines.filter(line => ref === line.documentReferenceId),
      ];
    });
    const newRefs = newLines.map<string>(line => line.documentReferenceId);
    this.form.setFieldsValue({
      doNumber: removeDuplicate(newRefs),
      salesOrder: removeDuplicate(newRefs),
    });
  }

  async setDefaultFieldValue(): Promise<void> {
    const currency =
      LocalStorageService.load(PREFERENCE_FEATURE_KEY.FEATURE_BASE_CURRENCY) ||
      "IDR";

    const values = {
      currency,
      taxCalculation: TAX_CALCULATION.EXCLUSIVE,
      assignedBy: "",
    };

    // side effect to set receivable account by currency
    this.findReceivableAccountByCurrency(currency);

    // set default value from preference
    const prefAssignee: IPreferencesResponseDto | undefined =
      this.getPreferenceByKey("feature_default_assignee");
    if (prefAssignee && prefAssignee.value) {
      this.assigneeId = prefAssignee.value;
      values.assignedBy = prefAssignee.name;
    }

    this.form.setFieldsValue(values);
    this.setForm({
      ...this.storeForm,
      taxCalculation: this.form.getFieldValue("taxCalculation"),
      assignee: values.assignedBy,
    });
  }

  doPricingCalc(): void {
    this.calcPricingProduct();
    this.calcTotal();
    this.calcTotalTax();
    this.calcGrandTotal();
  }

  changeCustomerShipAddress(value: string): void {
    this.tempData.shortLocation = this.dataCustomerShipAddress.find(
      item => item.address == value
    )?.location as string;
    this.dataSourceDetailsTabpane.forEach(item => {
      item.location = this.tempData.shortLocation;
    });
  }

  getCreateInvoiceRecuring(recurringIds: Array<string>): void {
    invoiceServices
      .getDetailInvoiceRecurring(recurringIds)
      .then(res => {
        this.checkDropdown(res);
        this.showHideDoSo(INVOICE_SOURCE.RECURING_INVOICE);
        this.getContactDataDetail(res.customerId, "detailRecurring");
        this.changeCurrency(res.priceCurrency);
        res.icBillings.forEach(item => {
          item["billingPeriod"] =
            moment(item.startDate).format(DEFAULT_DATE_FORMAT) +
            " - " +
            moment(item.endDate).format(DEFAULT_DATE_FORMAT);
        });
        this.dataDetailRecuring = res;
        this.typeCurrency = res.priceCurrency;
        this.changeSource += 1;
        this.form.setFieldsValue({
          customerName: res.customerId,
          branch: res.branchWarehouseId,
          source: INVOICE_SOURCE.RECURING_INVOICE,
          currency: res.priceCurrency,
          poNumber: res.customerPoNumber,
        });
        this.showProductRecurring(res);
        this.disabled.currency = true;
        this.findCurrencyRate(res.priceCurrency);

        // find prepayment
        this.queryParams.invoicePrepayment.search =
          this.createQuerySearchPrepayment();
        this.getListInvoicePrepayment(this.queryParams.invoicePrepayment);
      })
      .catch(() => (this.disabled.currency = false));
  }

  getDetailInvoiceAr(): void {
    invoiceServices.getDetailInvoiceAR(this.$route.params.id).then(res => {
      /**
       * set currency field on disabled state
       * so it should not be change after data submitted
       * from SO / DO
       */
      this.disabled.currency = true;

      this.show.details = res.invoiceSource === INVOICE_SOURCE.RECURING_INVOICE;

      this.detailDataInvoiceAr = res;
      this.typeCurrency = res.currency;
      this.journalNumber.id = res.journalId;
      this.journalNumber.name = res.journalNo;
      this.checkDropdown(res);
      this.showHideDoSo(res.invoiceSource as INVOICE_SOURCE);

      //#region set form value
      this.form.setFieldsValue({
        invoiceType: res.invoiceType || "",
        source: res.invoiceSource || "",
        branch: res.branchWarehouseId || "",
        customerName: res.customerId || "",
        customerShipAddress: res.customerShipToAddress || "",
        customerBillAddress: res.customerBillToAddress || "",
        currency: res.currency || "",
        rate: res.currencyRate || ONE,
        taxCalculation: res.taxType || "",
        termOfPayment: res.termOfPayment?.toString() || "0",
        invoiceNumber: res.documentNumber || "",
        invoiceDate: res.invoiceDate ? moment(res.invoiceDate) : null,
        accountingDate: res.accountingDate ? moment(res.accountingDate) : null,
        invoiceReceiveDate: res.invoiceReceivedDate
          ? moment(res.invoiceReceivedDate)
          : null,
        operatorName: res.operatorName || "",
        invoiceDescription: res.description || "",
        statusInvoice: res.status || "",
        usageType: res.usageType || "",
        receivableAccount: res.receivableAccountId || "",
        doNumber: res.deliveryOrderIds || [],
        salesOrder: res.salesOrderIds || [],
        grNumber: res.customerGoodReceiptNo || "",
        poNumber: res.customerPurchaseOrderNo || "",
        assignedBy: res.assignedBy || "",
      });

      this.formTable.setFieldsValue({
        total: currencyFormat(res.dpp) || currencyFormat(0),
        totalTax: currencyFormat(res.vat) || currencyFormat(0),
        grandTotal: currencyFormat(res.grandTotal) || currencyFormat(0),
        discountAmountPercent: res.percentDiscount || 0,
        discountAmountValue: res.discountValue || 0,
        customerTaxType: res.customerTaxType,
        creditMemo: currencyFormat(res.creditMemoUsed) || currencyFormat(0),
        taxRegistrationNumber: res.taxRegistrationNumber || "",
        taxRegistrationName: res.taxRegistrationName || "",
        taxInvoiceDate: res.taxInvoiceDate ? moment(res.taxInvoiceDate) : null,
        taxInvoiceNumber: res.taxInvoiceNumber || "",
        prepaymentUsed: currencyFormat(res.prepaymentUsed) || currencyFormat(0),
        remainingInvoiceAmount:
          currencyFormat(res.remainingInvoiceAmount) || currencyFormat(0),
        return: currencyFormat(res.returnAmount) || currencyFormat(0),
        joinInvoice: res.invoiceJoinNo,
        accountReceivables:
          res.status !== STATUS.POSTED ? res.receivableAccount : "",
        taxInvoiceHasBeenUploaded: res.taxIsUploaded,
      });
      this.setForm({
        taxCalculation: (res.taxType || "") as TAX_CALCULATION,
        additionalDiscount: {
          amount: changeCurrencytoNumeric(res.discountValue),
          percent: res.percentDiscount,
        },
        assignee: res.assignedBy,
      });
      //#endregion

      // fetch prepayment
      this.queryParams.invoicePrepayment.search =
        this.createQuerySearchPrepayment();
      this.getListInvoicePrepayment(
        this.queryParams.invoicePrepayment,
        res.applyPrepayment.prepaymentLines
      );

      //#region assign prepayment
      if (res.applyPrepayment) {
        const docPrepayment: TableDataAplyPrepayment[] = [];
        res.applyPrepayment.prepaymentLines.forEach((item, idx) => {
          const dataObj = {
            appliedAmount: item.appliedAmount,
            description: item.description,
            invoicePrepaymentId: item.invoicePrepaymentId,
            id: item.id,
          };
          this.listId.push(item.invoicePrepaymentId);
          this.dataPrepaymentLines.push(dataObj);
          docPrepayment.push({
            no: idx + 1 + ".",
            id: item.id,
            description: item.description || "",
            invoicePrepaymentNumber: item.invoicePrepaymentNo || "",
            invoicePrepaymentNumberId: item.invoicePrepaymentId || "",
            amount: currencyFormat(item.appliedAmount) || currencyFormat(0),
            key: idx,
            disabledInput: true,
            disabledSelect: true,
            add: this.$t("lbl_add").toString(),
            disableByRow: ALLOW_APPLY_PREPAYMENT.includes(res.status)
              ? null
              : [this.$t("lbl_add").toString()],
          });
        });
        this.setPrepayment(docPrepayment);
        this.formTable.setFieldsValue({
          totalPrepayment: this.calcSumPrepayment.raw,
        });
      }
      //#endregion

      //#region map invoice ar lines detail into table
      this.dataSourceDetailsTabpane = res.invoiceARLines.map<TableDataDetails>(
        (element, i) => ({
          no: (i + 1).toString(),
          documentReference: element.documentReference || "",
          productId: element.productId,
          productCode: element.productCode || "",
          productName: element.productName || "",
          merk: element.merk || "",
          qty: element.qty || 0,
          uomId: element.uomId || "",
          uom: element.uom || "",
          id: element.id,
          taxRate: element.taxRate || 0,
          includePPh: element.includePPh,
          price: element.price || 0,
          revenueAccountId: element.revenueAccountId || "",
          revenueAccount: element.revenueAccount || "",
          baseAmount: element.baseAmount || 0,
          billingPeriodModal: element.billingDate
            ? moment(element.billingDate).format(DEFAULT_DATE_FORMAT)
            : "",
          taxValue: element.taxValue || 0,
          subTotal: element.subTotal || 0,
          description: element.description || "",
          location: element.customerLocation || "",
          incomeAccountTaxId: element.incomeAccountTaxId || "",
          percentDiscount: element.percentDiscount || 0,
          discountValue: element.discountValue || 0,
          incomeAccountTaxRatePercent: element.incomeAccountTaxRate || 0,
          incomeAccountTaxRateValue: element.incomeAccountTaxValue || 0,
          salesOrderLineId: element.salesOrderLineId,
          deliveryOrderLineId: element.deliveryOrderLineId,
          key: i,
          taxCode: { label: element.taxCode, key: element.taxId },
          optionCustom: [
            {
              name: "uomId",
              option: [{ name: element.uom, id: element.uomId }],
            },
          ] as OptionModel[],
          grossAfterDiscount: 0,
          productType: element.productType || "",
          proRateRatio: 0,
          proRateAdditionalDiscountAmount: 0,
          amountAfterProRateAdditionalDiscount: 0,
          proRateAmountAfterAdditionalDiscountRatio: 0,
          proRatePrepaymentAmount: 0,
          salesId: element.salesId || "",
          salesName: element.salesName || "",
          disabled: DISABLE_ROW.includes(res.status as STATUS),
          disableByRow: this.setDisableByRowDetail(element),
          customerLocation: element.customerLocation || "",
          documentReferenceId: element.documentReferenceId || "",
          internalContractBillingId: element.internalContractBillingId ?? "",
        })
      );
      //#endregion

      this.setDisableDocument(res.status as STATUS);
      this.setDataSourceDetailsTabPane(this.dataSourceDetailsTabpane);
      this.doPricingCalc();
      if (res.invoiceSource === INVOICE_SOURCE.INVOICE_OTHER) {
        this.checkdropdownSo(res);
      } else {
        this.checkdropdownDo(res);
      }

      this.getContactDataDetail(res.customerId, "detail");
      if (res.status !== STATUS.DRAFT) {
        this.disabled.print = false;
      }
    });
  }

  checkdropdownDo(data: ResponseDetailInvoiceAR): void {
    if (!data.deliveryOrderIds) return;
    data.deliveryOrderIds.forEach(element => {
      if (this.dataDoNumber.findIndex(item => item.value === element) === -1) {
        this.findListDOById(element);
      }
    });
  }

  findListDOById(idDo: string): void {
    salesOrderServices
      .getDeliveryOrderList({ search: `secureId~${idDo}` })
      .then(({ data }) => {
        const [item] = data;
        if (!item) return;
        this.dataDoNumber.push({
          value: item.id,
          documentNumber: item.documentNumber,
        });
      });
  }

  showProductRecurring(data): void {
    const dataTempId: Array<string> = [];
    data.deliveryOrders?.forEach(element => {
      dataTempId.push(element.deliveryOrderId);
    });
    let dataObj = {} as TableDataDetails;
    this.listPoNumber = [];
    data.icBillings.forEach((element, index) => {
      this.listPoNumber.push(element.customerPoNumber);
      dataObj = {
        no: index + 1,
        documentReference: element.bastNo as string,
        productId: element.productId as string,
        productCode: element.productCode as string,
        productName: element.productName as string,
        merk: element.merk,
        qty: element.qtyOutstanding || 0,
        uomId: element.productUomId as string,
        uom: element.productUom as string,
        price: element.price || 0,
        includePPh: false,
        baseAmount: "",
        taxCode: { key: element.taxId, label: element.taxCode },
        taxRate: element.taxRate || 0,
        deliveryOrderLineId: element.id,
        taxValue: "",
        subTotal: "",
        description: data.description,
        location: this.tempData.shortLocation,
        incomeAccountTaxId: "",
        percentDiscount: 0,
        discountValue: element.discountValue || 0,
        key: index,
        disableByRow: ["revenueAccount", "incomeAccountTaxId"],
        optionCustom: [
          {
            name: "uomId",
            option: [{ name: element.productUom, id: element.productUomId }],
          },
        ] as OptionModel[],
        billingPeriodModal: moment(
          this.dataDetailRecuring.icBillings[index].billingDate
        ).format(DEFAULT_DATE_FORMAT),
        revenueAccountId: element.revenueRentAccountId,
        revenueAccount: element.revenueRentAccount,
        invoiceICBillingLineId: null,
        internalContractBillingId: element.id,
        grossAfterDiscount: 0,
        productType: "",
        proRateRatio: 0,
        proRateAdditionalDiscountAmount: 0,
        amountAfterProRateAdditionalDiscount: 0,
        proRateAmountAfterAdditionalDiscountRatio: 0,
        proRatePrepaymentAmount: 0,
        salesId: element.salesId,
        salesName: element.salesName,
        customerLocation: element.customerLocation || "",
        documentReferenceId: element.documentReferenceId || "",
      };
      this.dataSourceDetailsTabpane.push(dataObj);
    });

    this.commitStore();
    this.doPricingCalc();

    this.dataGenerateTable.type = TYPE_SOURCE.RECURRING;
    this.dataGenerateTable.listId = dataTempId;
  }

  checkdropdownSo(data: ResponseDetailInvoiceAR): void {
    if (!data.salesOrderIds) return;
    data.salesOrderIds.forEach(element => {
      if (
        this.dataSalesOrder.findIndex(item => item.value === element) === -1
      ) {
        this.findListSOById(element);
      }
    });
  }

  findListSOById(idSo: string): void {
    salesOrderServices
      .getListSalesOrder({ search: `secureId~${idSo}` })
      .then(({ data }) => {
        const [item] = data;
        if (!item) return;
        this.dataSalesOrder.push({
          documentNumber: item.salesOrderNo,
          value: item.id,
        });
      });
  }

  /**
   * handle on change tax invoice uploaded
   */
  changeCheckbox(e): void {
    this.formTable.setFieldsValue({
      taxInvoiceHasBeenUploaded: e.target.checked,
    });
    this.getTaxInvoiceNumber(this.formTable.getFieldValue("customerTaxType"));
  }

  resetPrepayment(): void {
    this.onSelectChangeApplyPrepayment({ data: null }, true);
  }

  onAddRow(): void {
    const newRow: TableDataDetails = {
      no: this.dataSourceDetailsTabpane.length + 1 + ".",
      documentReference: "",
      productId: "",
      productCode: "",
      productName: "",
      includePPh: false,
      merk: "",
      qty: "",
      uomId: "",
      uom: "",
      price: "",
      revenueAccountId: "",
      revenueAccount: "",
      baseAmount: "",
      taxValue: "",
      taxCode: undefined,
      taxRate: 0,
      subTotal: "",
      description: "",
      location: this.tempData.shortLocation,
      incomeAccountTaxId: "",
      percentDiscount: 0,
      discountValue: 0,
      key: this.dataSourceDetailsTabpane.length,

      // holds options of each row
      optionCustom: [] as OptionModel[],

      grossAfterDiscount: 0,
      productType: PRODUCT_TYPE.SERVICE,
      proRateRatio: 0,
      proRateAdditionalDiscountAmount: 0,
      amountAfterProRateAdditionalDiscount: 0,
      proRateAmountAfterAdditionalDiscountRatio: 0,
      proRatePrepaymentAmount: 0,
      salesId: "",
      salesName: "",
      disableByRow: this.setDisabledRowAdd(),
      customerLocation: "",
      documentReferenceId: "",
    };

    this.dataSourceDetailsTabpane.push(newRow);
  }

  setDisabledRowAdd(): string[] {
    const columns: string[] = ["incomeAccountTaxId"];
    const source: string = this.form.getFieldValue("source");

    if (this.isTaxNone) {
      columns.push("taxCode");
    }

    if (!this.isRecurring && source !== "Invoice Other") {
      columns.push("includePPh");
    }

    return columns;
  }

  changeCustomerTax(value: string): void {
    this.form.setFieldsValue({ customerTaxType: value });
    if (value) {
      if (this.form.getFieldValue("invoiceDate")) {
        this.getTaxInvoiceNumber(value);
      } else {
        this.showNotifError("lbl_invoice_date_mandatory");
        this.formTable.setFieldsValue({
          customerTaxType: "",
        });
      }
    } else {
      this.formTable.setFieldsValue({
        taxInvoiceNumber: "",
      });
    }
  }

  onViewMore(param: { record: TableDataDetails; index: number }): void {
    const { index, record } = param;
    this.modal.row.show = true;
    this.modal.row.index = index;
    this.modal.row.data = record;

    const values = {
      includePPh: this.dataSourceDetailsTabpane[index].includePPh,
      productCode: this.dataSourceDetailsTabpane[index].productCode,
      productName: this.dataSourceDetailsTabpane[index].productName,
      productMerk: this.dataSourceDetailsTabpane[index].merk,
      productQty: this.dataSourceDetailsTabpane[index].qty,
      productUom: this.dataSourceDetailsTabpane[index].uom,
      billingPeriodModal:
        this.dataSourceDetailsTabpane[index].billingPeriodModal,
      price: this.dataSourceDetailsTabpane[index].price,
      discountValue: this.dataSourceDetailsTabpane[index].discountValue,
      percentDiscount: this.dataSourceDetailsTabpane[index].percentDiscount,
      incomeTaxRatePercent:
        this.dataSourceDetailsTabpane[index].incomeAccountTaxRatePercent,
      incomeTaxRateValue:
        this.dataSourceDetailsTabpane[index].incomeAccountTaxRateValue,
      baseAmount: this.dataSourceDetailsTabpane[index].baseAmount,
    };

    for (const key in values) {
      this.formDetailModal.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.formDetailModal.setFieldsValue(values);
  }

  handleChangeMenu(value): void {
    this.activeTabPane = value;
  }
  cancel(): void {
    this.modal.row.show = false;
  }

  /**
   * handle delete row
   */
  showConfirmation(): void {
    if (!this.selectedRowKeysDetails.length) {
      this.showNotifError("lbl_modal_delete_error_description");
      return;
    }
    this.$confirm({
      title: this.$t("lbl_modal_delete_title_confirm"),
      content: this.$t("lbl_modal_delete_info", {
        count: this.selectedRowKeysDetails.length,
      }),
      onOk: this.handleDeleteRow,
    });
  }

  onSelectChangeDetails(selectedRowKeys: number[]): void {
    this.selectedRowKeysDetails = selectedRowKeys;
  }

  /**
   * handle delete prepayment line
   */
  onDeletePrepayment(): void {
    this.formTable.setFieldsValue({
      totalPrepayment: this.calcSumPrepayment.raw,
    });

    this.listId = this.storePrepayment.map(x => x.invoicePrepaymentNumberId);

    this.doPricingCalc();
  }

  /**
   * handler add prepayment
   */
  onSelectChangeApplyPrepayment({ data }, reset = false): void {
    if (reset) {
      this.setPrepayment([]);
      this.listId = [];
      this.doPricingCalc();
      return;
    }
    const reservedDoc = this.storePrepayment.find(
      doc => doc.invoicePrepaymentNumberId === data.invoicePrepaymentNumberId
    );
    if (reservedDoc) {
      this.showNotifError(this.$t("notif_error_duplicate_data").toString());
      return;
    }
    this.setPrepayment([...this.storePrepayment, data]);
    this.listId = this.storePrepayment.map(
      doc => doc.invoicePrepaymentNumberId
    );
    this.formTable.setFieldsValue({
      totalPrepayment: this.calcSumPrepayment.raw,
    });

    this.doPricingCalc();
  }

  cancelSaveDraftModal(): void {
    this.$router.push("/accountreceivables/invoices");
  }
  okSaveDraftModal(): void {
    this.onSubmit("submit");
  }

  /**
   * handle change input on modal detail row
   * and additional discount invoice
   */
  changeDataInput(
    value: number,
    type:
      | "modalDiscountPercent"
      | "modalDiscountAmount"
      | "additionalDiscountPercent"
      | "additionalDiscountAmount"
  ): void {
    const { index } = this.modal.row;
    const row = this.dataSourceDetailsTabpane[index];
    switch (type) {
      case "modalDiscountPercent":
        this.calcDiscountRow(value, row, "percent");

        // set base amount (DPP) on detail row
        row.baseAmount =
          this.storeTableDetails[this.modal.row.index].baseAmount;
        this.commitStore();
        break;
      case "modalDiscountAmount":
        this.calcDiscountRow(value, row, "amount");

        // set base amount (DPP) on detail row
        row.baseAmount =
          this.storeTableDetails[this.modal.row.index].baseAmount;
        this.commitStore();
        break;
      case "additionalDiscountPercent":
        this.calcAdditionalDiscount(value, "percent");
        break;
      case "additionalDiscountAmount":
        this.calcAdditionalDiscount(value, "amount");
        break;
      default:
        break;
    }
    this.doPricingCalc();
  }

  calcDiscountRow(
    value: number,
    row: TableDataDetails,
    source: "percent" | "amount"
  ): void {
    const gross = new Decimal(row.qty || 0).times(
      changeCurrencytoNumeric(row.price) || 0
    );
    if (source === "percent") {
      const percentage = new Decimal(value).dividedBy(100);
      const final = new Decimal(gross || 0).times(percentage).toNumber();
      row.discountValue = final;
      row.percentDiscount = value;
      this.formDetailModal.setFieldsValue({
        discountValue: final,
        percentDiscount: value,
      });
    } else {
      const amount = new Decimal(value);
      const final = amount
        .dividedBy(gross || 1)
        .times(100)
        .toNumber();
      row.discountValue = value;
      row.percentDiscount = final;
      this.formDetailModal.setFieldsValue({
        discountValue: value,
        percentDiscount: final,
      });
    }
  }

  calcAdditionalDiscount(value: number, source: "percent" | "amount"): void {
    const totalInvoice: number = changeCurrencytoNumeric(
      this.formTable.getFieldValue("total")
    );
    if (source === "percent") {
      const percentage = new Decimal(value).dividedBy(100);
      const final = new Decimal(totalInvoice || 0).times(percentage).toNumber();
      this.formTable.setFieldsValue({
        discountAmountValue: final,
        discountAmountPercent: value,
      });
    } else {
      const amount = new Decimal(value);
      const final = amount
        .dividedBy(totalInvoice || 1)
        .times(100)
        .toNumber();
      this.formTable.setFieldsValue({
        discountAmountValue: value,
        discountAmountPercent: final,
      });
    }
    this.setForm({
      ...this.storeForm,
      additionalDiscount: {
        amount: changeCurrencytoNumeric(
          this.formTable.getFieldValue("discountAmountValue")
        ),
        percent: this.formTable.getFieldValue("discountAmountPercent"),
      },
    });
    this.doPricingCalc();
  }

  generateData(): void {
    this.listPoNumber = [];

    /**
     * set currency field on disabled state
     * so it should not be change after generate data
     * from SO / DO
     */
    this.disabled.currency = true;

    if (this.dataGenerateTable.type === "so") {
      const promises = this.dataGenerateTable.listId.map(el =>
        salesOrderServices.getDetailSalesOrder(el)
      );
      this.generateFromSo(promises);
    } else {
      const promises = this.dataGenerateTable.listId.map(el =>
        deliveryOrderServices.getDetailDeliveryOrder(el)
      );
      this.generateFromDo(promises);
    }
  }

  changeDataForm(value: string[], type: "so" | "do"): void {
    this.dataGenerateTable.type = type;
    this.dataGenerateTable.listId = value;
    if (value) this.changeSource += 1;
  }

  generateFromSo(promises: Promise<SalesOrderResponseDto>[]): void {
    Promise.all(promises).then(details => {
      const taxTypes = details.map<string>(item => item.taxCalculation);
      if (isNotUnique(taxTypes)) {
        this.showNotifWarning("notif_document_has_different_tax_type");
        return;
      }

      const taxCalc: TAX_CALCULATION = toTitlecase(
        details[0]?.taxCalculation || ""
      ) as TAX_CALCULATION;

      this.setForm({
        ...this.storeForm,
        taxCalculation: taxCalc,
      });

      details.forEach(item => {
        this.generateLineSo(item);
        if (item.customerPoNumber) {
          this.listPoNumber.push(item.customerPoNumber);
        }
      });

      this.form.setFieldsValue({
        poNumber: this.listPoNumber.join(","),
        taxCalculation: taxCalc,
      });

      if (this.isTaxNone) {
        this.formTable.setFieldsValue({
          customerTaxType: "",
          taxInvoiceHasBeenUploaded: false,
          taxInvoiceDate: null,
          taxInvoiceNumber: "",
        });
      }

      this.commitStore();
      this.doPricingCalc();
    });
  }

  generateLineSo(res: SalesOrderResponseDto): void {
    const hasOutstanding = res.salesOrderLines.filter(
      item => item.qtyOutstanding
    );
    if (!hasOutstanding.length) {
      this.showNotifWarning("notif_qty_outstanding_zero_for_document-x", {
        docNumber: res.documentNumber,
      });
      return;
    }
    const lines = hasOutstanding.map<TableDataDetails>(
      (item, index: number) => ({
        no: index + 1 + "",
        documentReference: res.documentNumber || "",
        productId: item.productId || "",
        productCode: item.productCode || "",
        productName: item.productName || "",
        merk: item.merk || "",
        qty: item.qtyOutstanding || 0,
        uomId: item.uomId || "",
        uom: item.uomName || "",
        includePPh: false,
        price: item.price || 0,
        revenueAccountId: item.salesAccountId || "",
        revenueAccount: item.salesAccount || "",
        baseAmount: 0,
        taxValue: 0,
        taxCode: { label: item.taxCode, key: item.taxCodeId },
        taxRate: item.taxRate || 0,
        subTotal: 0,
        description: "",
        location: this.tempData.shortLocation || "",
        incomeAccountTaxId: "",
        incomeAccountTaxRatePercent: 0,
        incomeAccountTaxRateValue: 0,
        percentDiscount: item.percentDiscount || 0,
        discountValue: item.discountValue || 0,
        salesOrderLineId: item.id || "",
        key: index,
        optionCustom: [
          {
            name: "uomId",
            option: [{ name: item.uomName, id: item.uomId }],
          },
        ] as OptionModel[],
        grossAfterDiscount: 0,
        productType: item.productType || "",
        proRateRatio: 0,
        proRateAdditionalDiscountAmount: 0,
        amountAfterProRateAdditionalDiscount: 0,
        proRateAmountAfterAdditionalDiscountRatio: 0,
        proRatePrepaymentAmount: 0,
        salesId: res.salesId || "",
        salesName: res.salesName || "",
        disableByRow: this.setDisableByRowGenerate(),
        customerLocation: item.customerLocation || "",
        documentReferenceId: res.id || "",
      })
    );

    const { dataSourceDetailsTabpane } = this;
    this.dataSourceDetailsTabpane = [...dataSourceDetailsTabpane, ...lines].map(
      (item, i) => ({ ...item, key: i })
    );
  }

  onBlur(value, form): void {
    if (value.target.value && !this.formTable.getFieldError(`${form}`)) {
      let dataobj = {};
      dataobj[form] = this.formatTax(value.target.value);
      this.formTable.setFieldsValue(dataobj);
    }
  }
  onFocus(value, form): void {
    if (value.target.value && !this.formTable.getFieldError(`${form}`)) {
      let dataobj = {};
      dataobj[form] = this.formatTaxtoNumber(value.target.value);
      this.formTable.setFieldsValue(dataobj);
    }
  }
  dataTaxCode(value): void {
    this.listDataTaxCode = value;
  }

  generateFromDo(promises: Promise<DataDetailDeliveryOrder>[]): void {
    Promise.all(promises).then(details => {
      const taxTypes: TAX_CALCULATION[] = details.map<TAX_CALCULATION>(
        item => item.soTaxCalculation
      );
      if (isNotUnique(taxTypes)) {
        this.showNotifWarning("notif_document_has_different_tax_type");
        return;
      }
      const taxCalc: TAX_CALCULATION = toTitlecase(
        details[0]?.soTaxCalculation || ""
      ) as TAX_CALCULATION;
      this.setForm({
        ...this.storeForm,
        taxCalculation: taxCalc,
      });

      details.forEach(detail => {
        this.generateLineDo(detail);
        if (detail.customerPoNumber) {
          this.listPoNumber.push(detail.customerPoNumber);
        }
      });

      this.form.setFieldsValue({
        poNumber: this.listPoNumber.join(","),
        taxCalculation: taxCalc,
      });

      if (this.isTaxNone) {
        this.formTable.setFieldsValue({
          customerTaxType: "",
          taxInvoiceHasBeenUploaded: false,
          taxInvoiceDate: null,
          taxInvoiceNumber: "",
        });
      }

      this.commitStore();
      this.doPricingCalc();
    });
  }

  generateLineDo(res: DataDetailDeliveryOrder): void {
    let dataObj = {} as TableDataDetails;
    const hasOutstanding = res.deliveryOrderLines.filter(
      row => row.qtyOutstanding > 0
    );
    if (!hasOutstanding.length) {
      this.showNotifWarning("notif_qty_outstanding_zero_for_document-x", {
        docNumber: res.documentNumber,
      });
      return;
    }
    hasOutstanding.forEach(element => {
      dataObj = {
        no: this.dataSourceDetailsTabpane.length + 1 + ".",
        documentReference: res.documentNumber || "",
        productId: element.productId || "",
        productCode: element.productCode || "",
        productName: element.productName || "",
        merk: element.merk || "",
        includePPh: false,
        qty: element.qtyOutstanding || 0,
        uomId: element.productUomId || "",
        uom: element.productUom || "",
        price: element.price || 0,
        revenueAccountId: "",
        revenueAccount: "",
        baseAmount: "",
        deliveryOrderLineId: element.id,
        taxValue: "",
        taxCode: {
          label: element.productSalesTaxCode,
          key: element.productSalesTaxId,
        },
        taxRate: element.productSalesTaxRate || 0,
        subTotal: "",
        description:
          this.form.getFieldValue("source").toUpperCase() === "PRODUCT SALE"
            ? ""
            : res.description || "",
        location: this.tempData.shortLocation,
        incomeAccountTaxId: "",
        percentDiscount: 0,
        discountValue: element.discountValue || 0,
        key: this.dataSourceDetailsTabpane.length,
        optionCustom: [
          {
            name: "uomId",
            option: [{ name: element.productUom, id: element.productUomId }],
          },
        ] as OptionModel[],
        grossAfterDiscount: 0,
        productType: element.productType || "",
        proRateRatio: 0,
        proRateAdditionalDiscountAmount: 0,
        amountAfterProRateAdditionalDiscount: 0,
        proRateAmountAfterAdditionalDiscountRatio: 0,
        proRatePrepaymentAmount: 0,
        salesId: res.salesId || "",
        salesName: res.salesName || "",
        disableByRow: this.setDisableByRowGenerate(),
        customerLocation: element.customerLocation || "",
        documentReferenceId: res.id || "",
      };
      if (!this.isRecurring) {
        if (
          this.dataSourceDetailsTabpane.findIndex(
            item => item.deliveryOrderLineId == element.id
          ) === -1
        ) {
          if (this.form.getFieldValue("source") === INVOICE_SOURCE.ASSET_SALE) {
            dataObj.revenueAccountId = element.revenueRentAccountId;
            dataObj.revenueAccount = element.revenueRentAccount;
          } else {
            dataObj.revenueAccountId = element.salesAccountId;
            dataObj.revenueAccount = element.salesAccount;
          }
          this.dataSourceDetailsTabpane.push(dataObj);
        }
      }
    });
  }

  /**
   * calc grand total
   */
  calcGrandTotal(): void {
    const sumBaseAmount = new Decimal(
      changeCurrencytoNumeric(this.calcSumBaseAmount) || 0
    );
    const totalTax =
      changeCurrencytoNumeric(this.formTable.getFieldValue("totalTax")) || 0;
    let grandTotal = sumBaseAmount.plus(totalTax);

    if (this.isRecurring) {
      const additionalDiscount = changeCurrencytoNumeric(
        this.formTable.getFieldValue("discountAmountValue") || 0
      );
      grandTotal = sumBaseAmount
        .minus(this.calcSumPrepayment.raw)
        .minus(additionalDiscount)
        .plus(totalTax);
    }

    this.formTable.setFieldsValue({ grandTotal: grandTotal.toNumber() });
  }

  /**
   * calc sum of tax amount on each product
   */
  calcTotalTax(): void {
    const sumTaxValue = this.storeTableDetails.reduce((curr, value) => {
      const tax = changeCurrencytoNumeric(value.taxValue) || 0;
      return new Decimal(tax).plus(curr).toNumber();
    }, 0);
    this.formTable.setFieldsValue({ totalTax: sumTaxValue || 0 });
  }

  /**
   * calc sum of gross
   * gross = qty * price
   */
  calcTotal(): void {
    const sumGross = this.storeTableDetails.reduce((curr, value) => {
      const qty = changeCurrencytoNumeric(value.qty) || 0;
      const price = changeCurrencytoNumeric(value.price) || 0;
      const gross = new Decimal(qty).times(price);
      return gross.plus(curr).toNumber();
    }, 0);
    this.formTable.setFieldsValue({ total: sumGross || 0 });
  }

  /**
   * set data source table into store
   */
  commitStore(): void {
    this.setDataSourceDetailsTabPane(this.dataSourceDetailsTabpane);
  }

  /**
   * handle data change on apply prepayment tab pane
   */
  onChangeTablePrepayment({
    pagination,
  }: {
    pagination: { pageSize: number; total: number; current: number };
  }): void {
    this.queryParams.invoicePrepayment.page = pagination.current - 1;
    this.queryParams.invoicePrepayment.search =
      this.createQuerySearchPrepayment();
    this.getListInvoicePrepayment(this.queryParams.invoicePrepayment);
  }

  createQuerySearchPrepayment(): string {
    const { customerName, branch, currency } = this.form.getFieldsValue();
    const searchBy: string[] = [
      `invoicePrepaymentStatus~${PREPAYMENT_STATUS.UN_APPLIED}`,
    ];

    if (customerName) {
      searchBy.push(`customer.secureId~${customerName}`);
    }

    if (branch) {
      searchBy.push(`branchWarehouse.secureId~${branch}`);
    }

    if (currency) {
      searchBy.push(`priceCurrency.currencyCode~${currency}`);
    }

    return searchBy.join("_AND_");
  }

  /**
   * get list invoice prepayment
   * by customer
   */
  getListInvoicePrepayment(
    params: RequestQueryParamsModel,
    data?: PrepaymentLine[]
  ): void {
    const { customerName, branch, currency } = this.form.getFieldsValue();
    if (!currency || !customerName || !branch) {
      this.loading.prepaymentRefresh = false;
      return;
    }

    this.loading.prepaymentTable = true;
    invoicePrepayment
      .getListInvoicePrepayment(params)
      .then((res: InvoicePrepayment) => {
        this.dataSourceApplyPrepaymentTabpane.totalElements = res.totalElements;
        this.dataSourceApplyPrepaymentTabpane.totalPages = res.totalPages;
        this.dataSourceApplyPrepaymentTabpane.data = res.data.map(
          (element, i) => ({
            no: setNumbering(params.page || 0, params.limit || 0, i).toString(),
            description: element.description as string,
            invoicePrepaymentNumber: element.invoiceNumber,
            invoicePrepaymentNumberId: element.id,
            amount: currencyFormat(element.grossAmount) || currencyFormat(0),
            key: setNumbering(params.page || 0, params.limit || 0, i),
            disabledInput: true,
            disabledSelect: true,
            add: this.$t("lbl_add").toString(),
            disableByRow: this.isModeCreate
              ? []
              : this.setDisableAddPrepayment(),
          })
        );
        if (!data || !data.length) return;
        this.dataSourceApplyPrepaymentTabpane.data.forEach(item => {
          item.disableByRow = this.isModeCreate
            ? []
            : this.setDisableAddPrepayment();
          for (const i of data) {
            if (i.invoicePrepaymentId === item.invoicePrepaymentNumberId) {
              item.id = i.id;
            }
          }
        });
      })
      .finally(() => {
        this.loading.prepaymentRefresh = false;
        this.loading.prepaymentTable = false;
      });
  }

  setDisableAddPrepayment(): string[] | null {
    const statusInvoice = this.form.getFieldValue("statusInvoice") as STATUS;
    if (ALLOW_APPLY_PREPAYMENT.includes(statusInvoice)) {
      return null;
    } else {
      return [this.$t("lbl_add").toString()];
    }
  }

  getDataSoOrDo(): void {
    if (this.form.getFieldValue("source") !== INVOICE_SOURCE.INVOICE_OTHER) {
      this.getDoNumber("");
    } else {
      this.getSalesOrder("");
    }
  }

  changeDropDown(value, type): void {
    if (!value) return;
    switch (type) {
      case "branch":
        this.form.setFieldsValue({ branch: value });
        this.getDataSoOrDo();
        this.queryParams.invoicePrepayment.search =
          this.createQuerySearchPrepayment();
        this.getListInvoicePrepayment(this.queryParams.invoicePrepayment);
        break;
      case "customerName":
        this.form.setFieldsValue({
          customerName: value,
        });
        this.getDataSoOrDo();
        this.queryParams.invoicePrepayment.page = 0;
        this.queryParams.invoicePrepayment.search =
          this.createQuerySearchPrepayment();
        this.getListInvoicePrepayment(this.queryParams.invoicePrepayment);
        this.getContactDataDetail(value, "create");
        this.dataSourceDetailsTabpane.forEach(item => {
          item.location = "";
        });
        break;
      case "source":
        this.resetForm();
        this.disabled.currency = false;
        this.dataGenerateTable.listId = [];
        this.showHideDoSo(value);
        this.dataSourceDetailsTabpane = [];
        this.getDataSoOrDo();
        break;
      case "invoiceDate":
        this.form.setFieldsValue({
          accountingDate: value,
          invoiceDate: value,
        });
        this.formTable.setFieldsValue({
          taxInvoiceDate: value,
        });

        if (this.isModeCreate && this.form.getFieldValue("customerName")) {
          this.setCustomerTax(this.form.getFieldValue("customerName"));
        } else if (this.docId) {
          this.getTaxInvoiceNumber(
            this.formTable.getFieldValue("customerTaxType")
          );
        }
        break;
      default:
        break;
    }
  }

  setCustomerTax(custId: string): void {
    const { findOne } = useContactData();
    findOne(custId).then(({ customerData: { taxInvoiceCode } }) => {
      this.formTable.setFieldsValue({
        customerTaxType: taxInvoiceCode,
      });
      this.getTaxInvoiceNumber(taxInvoiceCode);
    });
  }

  /**
   * set income tax for each line
   */
  setIncomeTax(value: string, index: number, options: OptionModel[]): void {
    this.dataSourceDetailsTabpane[index].incomeAccountTaxId = value;
    const tax = options.find(item => item.id === value);
    this.dataSourceDetailsTabpane[index].incomeAccountTaxRatePercent =
      tax?.rate || 0;

    this.commitStore();
  }

  showHideDoSo(value: INVOICE_SOURCE): void {
    if (TYPE_SELECT_DO.includes(value) && !this.isRecurring) {
      this.show.so = false;
      this.show.do = true;
      this.dataGenerateTable.type = "do";
      this.changeSource += 1;
    } else if (TYPE_SELECT_SO.includes(value) && !this.isRecurring) {
      this.show.do = false;
      this.show.so = true;
      this.dataGenerateTable.type = "so";
      this.changeSource += 1;
    } else {
      this.show.so = false;
      this.show.do = false;
    }
  }
  resetForm(): void {
    this.form.setFieldsValue({
      branch: "",
      customerName: "",
      customerBillAddress: "",
      customerShipAddress: "",
      salesOrder: [],
      doNumber: [],
      salesName: "",
      termOfPayment: "",
      invoiceDate: null,
      accountingDate: null,
      currency: "",
      rate: "",
      receivableAccount: "",
      operatorName: "",
      usageType: "",
      invoiceDescription: "",
    });
    this.formTable.setFieldsValue({
      customerTaxType: "",
      taxRegistrationNumber: "",
      taxRegistrationName: "",
      taxInvoiceNumber: "",
      total: 0,
      discountAmountPercent: 0,
      discountAmountValue: 0,
      totalTax: 0,
      grandTotal: 0,
      taxInvoiceDate: null,
    });
  }

  /**
   * handle missing options
   */
  checkDropdown(
    data: ResponseDetailInvoiceAR | InvoiceIcBillingResponseDto
  ): void {
    if (
      data.branchWarehouseId &&
      this.dataBranch.findIndex(item => item.id === data.branchWarehouseId) ===
        -1
    ) {
      this.dataBranch.push({
        code: "",
        createdDate: "",
        id: data.branchWarehouseId,
        modifiedDate: "",
        name: data.branchWarehouseName,
        address: "",
      });
    }
    if (
      data.customerId &&
      this.dataCustomer.findIndex(item => item.id === data.customerId) === -1
    ) {
      this.dataCustomer.push({
        id: data.customerId,
        firstName: data.customerName,
        addressDataList: [
          {
            address:
              "customerBillToAddress" in data ? data.customerBillToAddress : "",
            billTo: true,
            shipTo: false,
          },
          {
            address:
              "customerShipToAddress" in data ? data.customerShipToAddress : "",
            billTo: false,
            shipTo: true,
          },
        ],
      });
    }
  }

  handlePrint(): void {
    this.modalPrint.toggle();
  }

  getContactDataDetail(
    customerId: string,
    type: "detail" | "create" | "detailRecurring"
  ): void {
    contactServices.getContactData(customerId).then(res => {
      if (type === "create" || type === "detailRecurring") {
        this.form.setFieldsValue({
          termOfPayment: res.top,
        });
        this.setCustomerAddress(res.addressDataList);

        //#region check tax registration number
        if (res.taxRegisNumber === " " || res.taxRegisNumber === "-") {
          res.addressDataList.forEach(element => {
            if (element.idCardNumber === " " || element.idCardNumber === "-") {
              this.disabled.taxRegistrationNumber = false;
              this.formTable.setFieldsValue({
                taxRegistrationNumber: "",
              });
            } else {
              this.formTable.setFieldsValue({
                taxRegistrationNumber: element.idCardNumber,
              });
              this.disabled.taxRegistrationNumber = true;
            }
          });
        } else {
          this.formTable.setFieldsValue({
            taxRegistrationNumber: res.taxRegisNumber,
          });
          this.disabled.taxRegistrationNumber = true;
        }
        //#endregion

        //#region check tax registration name
        if (res.taxRegisName == " " || res.taxRegisName == "-") {
          this.formTable.setFieldsValue({
            taxRegistrationName: "",
          });
          this.disabled.taxRegistrationName = false;
        } else {
          this.disabled.taxRegistrationName = true;
          this.formTable.setFieldsValue({
            taxRegistrationName: res.taxRegisName,
          });
        }
        //#endregion

        //#region check tax invoice code
        if (res.customerData.taxInvoiceCode) {
          this.formTable.setFieldsValue({
            customerTaxType: this.isTaxNone
              ? ""
              : res.customerData.taxInvoiceCode,
          });
          if (!this.isTaxNone && this.form.getFieldValue("invoiceDate")) {
            this.getTaxInvoiceNumber(res.customerData.taxInvoiceCode);
          }
        } else {
          this.formTable.setFieldsValue({
            customerTaxType: "",
            taxInvoiceNumber: "",
          });
        }
        //#endregion
      }

      this.setAddressOptions(res.addressDataList);
    });
  }

  setAddressOptions(address: CustomAddressDataList[]): void {
    this.dataCustomerBillAddress = address.filter(item => item.billTo);
    this.dataCustomerShipAddress = address.filter(item => item.shipTo);
  }

  getTaxInvoiceNumber(id: string): void {
    const { taxInvoiceHasBeenUploaded, taxInvoiceNumber } =
      this.formTable.getFieldsValue();
    const { invoiceDate } = this.form.getFieldsValue();
    const params: RequestQueryParamsModel = {
      taxHasBeenUploaded: taxInvoiceHasBeenUploaded,
      invoiceDate: moment(invoiceDate).format(DATE_FORMAT_YYYY_MM_DD),
      taxInvoiceSerialNumber: taxInvoiceNumber,
    };
    invoiceServices.getInvoiceCode(id, params).then(res => {
      this.formTable.setFieldsValue({
        taxInvoiceNumber: res,
      });
    });
  }

  onSubmit(type: Submit): void {
    const dataInvoiceARLines = [] as InvoiceARLines[];
    let listDeletedId = [] as string[];
    if (type === "back") {
      this.$confirm({
        title: this.$t("lbl_leave_page"),
        onOk: () => {
          this.$router.push("/accountreceivables/invoices");
        },
        onCancel() {
          return;
        },
      });
    } else {
      if (!this.dataSourceDetailsTabpane.length) {
        this.showNotifError("lbl_invoice_lines_is_mandatory");
        return;
      }

      this.form.validateFields((err, value) => {
        if (!err) {
          this.formTable.validateFields((errTable, valueTable) => {
            if (!errTable) {
              this.dataSourceApplyPrepaymentTabpane.data.forEach(data => {
                if (this.listId.includes(data.invoicePrepaymentNumberId)) {
                  let dataObj = {
                    appliedAmount: changeCurrencytoNumeric(data.amount),
                    description: data.description,
                    invoicePrepaymentId: data.invoicePrepaymentNumberId,
                    id: data.id ?? "",
                  };
                  const idxPrepaymentLines = this.dataPrepaymentLines.findIndex(
                    item =>
                      item.invoicePrepaymentId ===
                      data.invoicePrepaymentNumberId
                  );
                  if (idxPrepaymentLines === -1) {
                    this.dataPrepaymentLines.push(dataObj);
                  }
                }
              });
              if (this.listId.length < 1) {
                this.dataPrepaymentLines?.forEach(item => {
                  if (item.id) listDeletedId.push(item.id);
                });
                this.dataPrepaymentLines = [];
              } else {
                this.dataPrepaymentLines?.forEach((item, index) => {
                  if (!this.listId.includes(item.invoicePrepaymentId)) {
                    if (item.id) listDeletedId.push(item.id);
                    this.dataPrepaymentLines.splice(index, 1);
                  }
                });
              }
              this.dataSourceDetailsTabpane?.forEach(item => {
                const objInvoiceARLines = {
                  baseAmount: changeCurrencytoNumeric(item.baseAmount) || 0,
                  description: item.description,
                  includePPh: item.includePPh,
                  incomeAccountTaxId: item.includePPh
                    ? item.incomeAccountTaxId
                    : "",
                  merk: item.merk,
                  percentDiscount: item.percentDiscount || 0,
                  discountValue: item.discountValue || 0,
                  productId: item.productId,
                  id: item.id || "",
                  qty: changeCurrencytoNumeric(item.qty) || 0,
                  price: changeCurrencytoNumeric(item.price) || 0,
                  revenueAccountId: item.revenueAccountId,
                  salesOrderLineId: item.salesOrderLineId,
                  deliveryOrderLineId: this.isRecurring
                    ? null
                    : item.deliveryOrderLineId,
                  subTotal: changeCurrencytoNumeric(item.subTotal) || 0,
                  taxId: item.taxCode?.key,
                  taxValue: changeCurrencytoNumeric(item.taxValue) || 0,
                  uomId: item.uomId,
                  invoiceICBillingLineId: null,
                  internalContractBillingId: item.internalContractBillingId,
                  customerLocation: item.customerLocation,
                  salesId: item.salesId,
                } as InvoiceARLines;
                dataInvoiceARLines.push(objInvoiceARLines);
              });
              let dataPost = {
                accountingDate: moment(value.accountingDate).format(),
                applyPrepayment: {
                  prepaymentLines: this.dataPrepaymentLines || [],
                  deletedPrepaymentLineIds: listDeletedId,
                },
                deletedInvoiceARLineIds: this.listDeletedIdDetails || [],
                usageType: value.usageType,
                branchWarehouseId: value.branch,
                currency: value.currency,
                customerBillToAddress: value.customerBillAddress,
                customerId: value.customerName,
                customerShipToAddress: value.customerShipAddress,
                customerTaxType: valueTable.customerTaxType,
                description: value.invoiceDescription,
                invoiceARLines: dataInvoiceARLines,
                invoiceDate: moment(value.invoiceDate).format(),
                invoiceSource: value.source,
                invoiceType: value.invoiceType,
                operatorName: value.operatorName,
                receivableAccountId: value.receivableAccount,
                customerGoodReceiptNo: value.grNumber,
                customerPurchaseOrderNo: value.poNumber,
                status: value.statusInvoice || STATUS.DRAFT,
                taxInvoiceDate: valueTable.taxInvoiceDate
                  ? moment(valueTable.taxInvoiceDate).format()
                  : null,
                taxIsUploaded: valueTable.taxInvoiceHasBeenUploaded,
                taxRegistrationName: valueTable.taxRegistrationName,
                taxRegistrationNumber: valueTable.taxRegistrationNumber,
                taxType: value.taxCalculation,
                termOfPayment: Number(value.termOfPayment),
                discountValue: valueTable.discountAmountValue || 0,
                percentDiscount: valueTable.discountAmountPercent || 0,
                taxInvoiceNumber: valueTable.taxInvoiceNumber,
                assignedBy: this.storeForm.assignee ?? "",
              } as PostInvoice;
              if (
                this.form.getFieldValue("source") !==
                INVOICE_SOURCE.INVOICE_OTHER
              ) {
                dataPost.deliveryOrderIds = value.doNumber;
              } else {
                dataPost.salesOrderIds = value.salesOrder;
              }

              if (this.typeCurrency && this.typeCurrency !== "IDR") {
                dataPost.currencyRate =
                  changeCurrencytoNumeric(value.rate) || ONE;
              }

              switch (type) {
                case "update":
                  this.loading.update = true;
                  invoiceServices
                    .updateInvoice(dataPost, this.$route.params.id)
                    .then(res => {
                      this.resetStore();
                      this.$router.push("/accountreceivables/invoices");
                      this.showNotifSuccess("notif_update_success", {
                        documentNumber: res.documentNumber || "",
                      });
                      this.listDeletedIdDetails = [];
                    })
                    .finally(() => (this.loading.update = false));
                  break;
                case "submit":
                  this.loading.submit = true;
                  if (value.statusInvoice === STATUS.DRAFT) {
                    invoiceServices
                      .submitInvoice(dataPost, this.$route.params.id)
                      .then(res => {
                        this.resetStore();
                        this.$router.push("/accountreceivables/invoices");
                        this.showNotifSuccess("notif_submit_success", {
                          documentNumber: res.documentNumber || "",
                        });
                        this.listDeletedIdDetails = [];
                      })
                      .finally(() => (this.loading.submit = false));
                  } else {
                    invoiceServices
                      .createInvoice(dataPost)
                      .then(res => {
                        this.resetStore();
                        this.$router.push("/accountreceivables/invoices");
                        this.showNotifSuccess("notif_create_success", {
                          documentNumber: res.documentNumber || "",
                        });
                        this.listDeletedIdDetails = [];
                      })
                      .finally(() => (this.loading.submit = false));
                  }
                  break;
                default:
                  break;
              }
            } else {
              this.showNotifMandatory(errTable);
            }
          });
        } else {
          this.showNotifMandatory(err);
        }
      });
    }
  }

  showNotifMandatory(err): void {
    let messages: string[] = [];
    for (const [key] of Object.entries(err)) {
      messages.push(key);
    }
    this.showNotifError("notif_field_mandatory_data", {
      data: messages.join(", "),
    });
  }

  handleCancelModalDetail(): void {
    this.modalOpenDetail = false;
  }

  /**
   * handler for selectbox on each row
   */
  handleSelectTableDetails(
    value: string | LabelInValue,
    key: string | number,
    col: string,
    recordOptions: OptionModel[]
  ): void {
    this.dataSourceDetailsTabpane[key] = {
      ...this.dataSourceDetailsTabpane[key],
      [col]: value,
    };

    //#region check selected value with options
    let options;

    // trick to avoid checker item.id === value
    // customer location column is not provided with id property
    // so it should not check its value
    if (col !== "customerLocation" && col !== "uomId") {
      options = recordOptions.find(item => item.id === value);
    }

    //#endregion

    if (value) {
      switch (col) {
        case "productCode":
          this.dataSourceDetailsTabpane[key].productId = options.id;
          this.dataSourceDetailsTabpane[key].productName = options.nameUse;
          this.dataSourceDetailsTabpane[key].productCode = options.code;
          this.dataSourceDetailsTabpane[key].merk = options.merk;
          this.dataSourceDetailsTabpane[key].uom = "";
          this.dataSourceDetailsTabpane[key].uomId = "";
          this.setBasedOnProduct(options, +key);
          this.commitStore();
          break;
        case "productName":
          this.dataSourceDetailsTabpane[key].productId = options.id;
          this.dataSourceDetailsTabpane[key].productCode = options.code;
          this.dataSourceDetailsTabpane[key].merk = options.merk;
          this.dataSourceDetailsTabpane[key].productName = options.name;
          this.setBasedOnProduct(options, +key);
          this.commitStore();
          break;
        case "uomId":
          this.commitStore();
          break;
        case "taxCode":
          this.dataSourceDetailsTabpane[key].taxRate =
            recordOptions.find(item => item.id === (value as LabelInValue).key)
              ?.rate || 0;
          this.commitStore();
          this.doPricingCalc();
          break;
        case "revenueAccount":
          this.dataSourceDetailsTabpane[key].revenueAccountId = options.id;
          this.commitStore();
          break;
        case "salesId":
          this.dataSourceDetailsTabpane[key].salesId = options.id;
          this.dataSourceDetailsTabpane[key].salesName = options.fullName;
          this.commitStore();
          break;
        case "incomeAccountTaxId":
          this.setIncomeTax(value as string, +key, recordOptions);
          break;
        case "customerLocation":
          this.dataSourceDetailsTabpane[key].customerLocation = value;
          this.commitStore();
          break;
        default:
          break;
      }
    } else {
      if (col == "productCode") {
        this.dataSourceDetailsTabpane[key].uom = "";
        this.dataSourceDetailsTabpane[key].uomId = "";
        this.dataSourceDetailsTabpane[key].location = "";
        this.dataSourceDetailsTabpane[key].productName = "";
        this.dataSourceDetailsTabpane[key].merk = "";
      } else if (col == "productName") {
        this.dataSourceDetailsTabpane[key].uom = "";
        this.dataSourceDetailsTabpane[key].uomId = "";
        this.dataSourceDetailsTabpane[key].location = "";
        this.dataSourceDetailsTabpane[key].productCode = "";
        this.dataSourceDetailsTabpane[key].merk = "";
      } else if (col === "incomeAccountTaxId") {
        this.setIncomeTax(value, +key, recordOptions);
      }
      this.commitStore();
    }
    this.dataSourceDetailsTabpane = this.dataSourceDetailsTabpane.slice();
  }

  setBasedOnProduct(options: OptionModel, index: number): void {
    productService.detailProduct(options.id).then(async res => {
      const { salesTaxName, salesTaxId, baseUnit, baseUnitId } = res;
      this.dataSourceDetailsTabpane[index].taxCode = {
        key: salesTaxId,
        label: salesTaxName,
      };
      this.dataSourceDetailsTabpane[index].taxRate = await useTaxRate(
        salesTaxId
      );
      this.dataSourceDetailsTabpane[index].uomId = baseUnitId;
      this.dataSourceDetailsTabpane[index].uom = baseUnit;

      this.setOptionUomOnRow(index, res);
    });
  }

  setOptionUomOnRow(
    index: number,
    { baseUnit, baseUnitId }: IProductResponse
  ): void {
    this.dataSourceDetailsTabpane[index].optionCustom = [
      {
        name: "uomId",
        option: [{ name: baseUnit, id: baseUnitId }],
      },
    ];
  }

  /**
   * @description
   * use invoice ar lines to show
   * IC billings detail
   */
  mapArLineToIcBillingDetail(
    arLines: Array<InvoiceARLine>
  ): Array<RowIcBiling> {
    return arLines.map((item, idx) => ({
      bastNo: item.documentReference,
      billingDate: item.billingDate ?? "",
      billingNo: item.billingNo ?? "",
      deliveryOrderNo: item.deliveryOrderNo,
      internalContractNo: item.internalContractNo,
      key: idx,
      rentPeriodDate: item.rentPeriodDate,
      salesOrderNo: item.salesOrderNo,
    }));
  }

  /**
   * @description
   * map ic billing line from ic billing detail dto
   * to ic billing detail modal
   */
  mapIcBillingsDtoToIcBillingDetail(
    data: InvoiceIcBillingLineResponseDto[]
  ): RowIcBiling[] {
    return data.map((item, i) => ({
      internalContractNo: item.internalContractNo,
      salesOrderNo: item.salesOrderNo,
      deliveryOrderNo: item.deliveryOrderNo,
      bastNo: item.bastNo,
      billingNo: item.billingNo?.toString() ?? "",
      billingDate: item.billingDate,
      rentPeriodDate: item.rentPeriodDate,
      key: i,
    }));
  }

  handleViewDetail({ name, visible }) {
    switch (name) {
      case "prepaymentUsed":
        this.titleModal = "prepaymentUsed";
        this.modalOpenDetail = visible;
        this.dataSourceModalPrepayment =
          this.detailDataInvoiceAr.applyPrepayment.prepaymentLines;
        break;
      case "creditMemo":
        this.titleModal = "creditMemo";
        this.modalOpenDetail = visible;
        this.dataSourceModalCreditMemo = this.detailDataInvoiceAr.applyCredit;
        break;
      case "paidStatus":
        this.titleModal = "paidStatus";
        this.modalOpenDetail = visible;
        this.dataSourceModalPaidStatus =
          this.detailDataInvoiceAr.invoiceARReceiptDetails;
        break;
      case "details":
        this.titleModal = "Details";
        this.modalOpenDetail = visible;
        if (this.mode === Mode.CREATE) {
          this.dataSourceDetails = this.mapIcBillingsDtoToIcBillingDetail(
            this.dataDetailRecuring.icBillings
          );
        } else {
          this.dataSourceDetails = this.mapArLineToIcBillingDetail(
            this.detailDataInvoiceAr.invoiceARLines
          );
        }
        break;
      default:
        break;
    }
  }

  changeIncludePPh(value: boolean, index: number): void {
    this.dataSourceDetailsTabpane[index].includePPh = value;
    const { disableByRow } = this.dataSourceDetailsTabpane[index];

    // check disabled only for column pph
    if (value) {
      this.dataSourceDetailsTabpane[index].disableByRow = disableByRow.filter(
        (item: string) => item !== "incomeAccountTaxId"
      );
    } else {
      disableByRow.push("incomeAccountTaxId");
      this.dataSourceDetailsTabpane[index].disableByRow = disableByRow;
      this.dataSourceDetailsTabpane[index].incomeAccountTaxId = "";
      this.dataSourceDetailsTabpane[index].incomeAccountTaxRatePercent = "";
    }

    this.commitStore();
  }

  getBranch(value: string): void {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (value) params.search = `name~*${value}*`;
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(res => (this.dataBranch = res.data))
      .finally(() => (this.loading.branch = false));
  }

  getSalesOrder(value: string): void {
    const { branch, customerName, currency } = this.form.getFieldsValue();
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: `branch.secureId~*${branch}*_AND_customer.secureId~*${customerName}*_AND_salesType~Others_AND_priceCurrency.currencyCode~*${currency}*`,
    };

    if (value) {
      params.search += `_AND_documentNumber~*${value}*`;
    }

    this.loading.salesOrder = true;
    salesOrderServices
      .getListAvailableSalesOrder(params)
      .then(res => {
        this.dataSalesOrder = res.data.map<OptionModel>(item => ({
          documentNumber: item.documentNumber,
          value: item.id,
        }));
      })
      .finally(() => (this.loading.salesOrder = false));
  }

  getDoNumber(value: string): void {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: `salesOrder.branch.secureId~*${this.form.getFieldValue(
        "branch"
      )}*_AND_customer.secureId~*${this.form.getFieldValue(
        "customerName"
      )}*_AND_salesOrder.salesType~*${this.form.getFieldValue(
        "source"
      )}*_AND_salesOrder.priceCurrency.currencyCode~*${this.form.getFieldValue(
        "currency"
      )}*`,
    };
    if (value) params.search += `_AND_documentNumber~*${value}*`;
    this.loading.doNumber = true;
    salesOrderServices
      .getListAvailableDeliveryOrder(params)
      .then(
        res =>
          (this.dataDoNumber = res.data.map<OptionModel>(item => ({
            documentNumber: item.documentNumber,
            value: item.id,
          })))
      )
      .finally(() => (this.loading.doNumber = false));
  }

  disabledDate(current: Moment): boolean {
    if (this.isModeCreate) {
      return current > moment();
    }
    const year = moment(this.detailDataInvoiceAr.invoiceDate).year();
    const JANUARY = 0;
    const DECEMBER = 11;
    const backYear = moment().year(year).month(JANUARY).date(1).startOf("day");
    const afterYear = moment().year(year).month(DECEMBER).date(31).endOf("day");
    return current < backYear || current > afterYear;
  }

  findCurrencyRate(fromCurrency: string): void {
    const baseCurr =
      LocalStorageService.load(StorageKeys.BASE_CURRENCY) || "IDR";
    let params = {
      search: `fromCurrency.currencyCode~${fromCurrency}_AND_toCurrency.currencyCode~${baseCurr}`,
    } as RequestQueryParamsModel;
    settingsServices.listOfCurrency(params, "").then(res => {
      const [curr] = res.data;
      this.form.setFieldsValue({
        rate: curr ? curr.rate : 1,
      });
    });
  }

  searchReceivableAccount(value = ""): void {
    const currency = this.form.getFieldValue("currency");
    (this.queryParams.receivableAccount.search = `parentAccount.code~110201*_AND_currency.currencyCode~*${currency}*_AND_code~${value}*_OR_description~${value}*`),
      this.getListReceivableAccount(this.queryParams.receivableAccount);
  }

  getListReceivableAccount(params: RequestQueryParamsModel): void {
    this.loading.receivableAccount = true;
    settingsServices
      .listOfCoa(params, "")
      .then(res => {
        this.dataReceivableAccount = res.data;
      })
      .finally(() => (this.loading.receivableAccount = false));
  }

  changeCurrency(value: string): void {
    this.form.setFieldsValue({ currency: value });
    this.typeCurrency = value || "";
    if (!value) return;
    this.getDataSoOrDo();
    if (value === "IDR") {
      this.setFormRules("rate", [this.setRequiredRules(false)]);
      this.form.setFieldsValue({ rate: 1 });
    } else {
      this.findCurrencyRate(value);
      this.setFormRules("rate", [this.setRequiredRules(true)]);
    }
    this.findReceivableAccountByCurrency(value);
    this.queryParams.invoicePrepayment.search =
      this.createQuerySearchPrepayment();
    this.getListInvoicePrepayment(this.queryParams.invoicePrepayment);
  }

  findReceivableAccountByCurrency(currency: string): void {
    let params = {
      limit: 10,
      page: 0,
      search: `parentAccount.code~110201*_AND_currency.currencyCode~${currency}`,
    } as RequestQueryParamsModel;
    this.loading.receivableAccount = true;
    settingsServices
      .listOfCoa(params, "")
      .then((res: ResponseListOfCoa) => {
        this.dataReceivableAccount = res.data;
        const [acc] = res.data as DataCoa[];
        if (currency === "IDR") {
          const account = LocalStorageService.load(
            StorageKeys.RECEIVABLE_ACCOUNT
          );
          this.form.setFieldsValue({
            receivableAccount: account || "",
          });
        } else {
          this.form.setFieldsValue({
            receivableAccount: acc?.id || "",
          });
        }
      })
      .finally(() => (this.loading.receivableAccount = false));
  }

  getInvoiceType(): void {
    this.loading.invoiceType = true;
    masterServices
      .listMaster({ name: "INVOICE_AR_TYPE" })
      .then(res => {
        this.dataInvoiceType = res.filter(
          e => e.value !== "Prepayment" && e.value !== "Credit Memo"
        );
        if (this.isModeCreate) {
          this.form.setFieldsValue({
            invoiceType: res[0].value,
          });
        }
      })
      .finally(() => (this.loading.invoiceType = false));
  }

  getSource(): void {
    this.loading.source = true;
    masterServices
      .listMaster({ name: "INVOICE_AR_SOURCE" })
      .then(res => {
        if (this.isRecurring) {
          this.dataListSource = res.filter(
            e => e.value === INVOICE_SOURCE.RECURING_INVOICE
          );
        } else {
          this.dataListSource = res.filter(
            e => e.value !== INVOICE_SOURCE.RECURING_INVOICE
          );
        }
      })
      .finally(() => (this.loading.source = false));
  }

  getCustomerName(value: string): void {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: `customer~true_AND_active~true`,
    };
    if (value) {
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    }
    this.loading.customer = true;
    contactServices
      .listContactData(params)
      .then(res => (this.dataCustomer = res.data))
      .finally(() => (this.loading.customer = false));
  }

  getTermOfPayment(): void {
    this.loading.termOfPayment = true;
    masterServices
      .listMaster({ name: "TOP" })
      .then(res => {
        this.dataTermOfPayment = res.sort((a, b) => {
          return Number(a.value) - Number(b.value);
        });
      })
      .finally(() => (this.loading.termOfPayment = false));
  }

  handleInputDetails(
    value: string,
    key: string | number,
    _objectColInput,
    objectColInputName: string
  ): void {
    this.dataSourceDetailsTabpane[key][objectColInputName] = value;
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  getCheckboxPropsDetail(record) {
    return {
      props: {
        disabled: record.disabled,
      },
    };
  }

  setDisableDocument(status: STATUS): void {
    // handle toggle print button
    const disallowPrint = [STATUS.DRAFT, STATUS.CANCELLED, STATUS.REJECTED];
    this.show.print = !disallowPrint.includes(status);

    if (STATUS.DRAFT === status) {
      this.show.submit = true;
      this.disabled.receivableAccount = true;
      this.show.update = true;
      this.show.reject = false;
    } else if (STATUS.NEED_APPROVAL === status) {
      this.show.submit = false;
      this.disabled.receivableAccount = false;
      this.show.update = true;
      this.show.reject = true;
    } else if (STATUS.DELIVERED === status || STATUS.UNPAID === status) {
      this.disabled.receivableAccount = true;
      this.show.update = true;

      if (STATUS.UNPAID === status && !!this.detailDataInvoiceAr.journalId) {
        this.show.update = false; // disallow update invoice if already had journal
      }

      this.show.reject = false;
      this.show.submit = false;
    } else {
      // disabled forms
      this.show.submit = false;
      this.show.update = false;
      this.show.reject = false;
      for (const key in this.disabled) {
        this.disabled[key] = true;
      }
      this.disableButton.generate = true;
      this.disableButton.addRow = true;
      this.disableButton.deleteRow = true;
    }
  }

  setDisableByRowDetail(row: InvoiceARLine): string[] {
    const { status } = this.detailDataInvoiceAr;
    if (
      status === STATUS.REJECTED ||
      status === STATUS.CANCELLED ||
      status === STATUS.RETURNED
    ) {
      return [
        "no",
        "documentReference",
        "productCode",
        "productName",
        "qty",
        "uom",
        "price",
        "revenueAccount",
        "taxCode",
        "description",
        "salesId",
        "incomeAccountTaxId",
        "includePPh",
      ];
    } else if (status === STATUS.DRAFT || status === STATUS.NEED_APPROVAL) {
      return row.includePPh
        ? ["revenueAccount"]
        : ["revenueAccount", "incomeAccountTaxId"];
    }
    return [];
  }

  setCustomerAddress(address: CustomAddressDataList[]): void {
    const billTo = address.find(item => item.primaryBillTo);
    this.form.setFieldsValue({
      customerBillAddress: billTo?.address || "",
    });

    const shipTo = address.find(item => item.primaryShipTo);
    this.form.setFieldsValue({
      customerShipAddress: shipTo?.address || "",
    });
  }

  onChangeSwitch(
    checked: boolean,
    rowKey: string | number,
    colName: "includePPh"
  ): void {
    if (colName === "includePPh") {
      this.changeIncludePPh(checked, +rowKey);
    }
  }

  setDisableByRowGenerate(): string[] {
    const { source } = this.form.getFieldsValue();
    // defaults disabled columns
    const defaults: string[] = ["salesId", "incomeAccountTaxId"];

    // set column include pph to be disabled
    // if its not recuring/invoice other
    if (!this.isRecurring && source !== "Invoice Other") {
      defaults.push("includePPh");
    }

    if (this.isTaxNone) {
      defaults.push("taxCode");
    }

    return defaults;
  }

  handleInputNumberDetails(
    value: number,
    rowKey: string | number,
    _column: PropsModel,
    columnName: string
  ): void {
    this.dataSourceDetailsTabpane[rowKey][columnName] = value;

    if (columnName === "qty" || columnName === "price") {
      this.doPricingCalc();
    }
  }

  /**
   * set form rules
   * @param field decorator name of each field
   * @param newRules new rules for field
   */
  setFormRules(field: string, newRules: PropsModel[]): void {
    const { rules } = this.formRules[field].decorator[1];
    this.formRules[field].decorator[1].rules = [...rules, ...newRules];
  }

  setRequiredRules(required: boolean) {
    return {
      required: required,
      message: () => this.$t(Messages.VALIDATION_REQUIRED_ERROR),
    };
  }

  onRefreshPrepayment(): void {
    this.loading.prepaymentRefresh = true;
    this.queryParams.invoicePrepayment.search =
      this.createQuerySearchPrepayment();
    this.getListInvoicePrepayment(this.queryParams.invoicePrepayment);
  }

  handleCancelInvoice(): void {
    const { invoiceNumber } = this.form.getFieldsValue();
    if (!invoiceNumber) return;

    this.loading.cancel = true;
    invoiceServices
      .cencelInvoice({ documentNumber: invoiceNumber }, this.$route.params.id)
      .then(() => {
        this.showNotifSuccess("notif_cancel_success", {
          documentNumber: invoiceNumber,
        });
        this.resetStore();
        this.$router.push("/accountreceivables/invoices");
      })
      .catch(() => {
        this.showNotifError("notif_cancel_fail");
      })
      .finally(() => (this.loading.cancel = false));
  }

  handeRejectInvoice(): void {
    const { invoiceNumber } = this.form.getFieldsValue();
    if (!invoiceNumber) return;
    this.loading.reject = true;
    invoiceServices
      .rejectInvoice({ documentNumber: invoiceNumber }, this.$route.params.id)
      .then(() => {
        this.showNotifSuccess("notif_reject_success", {
          documentNumber: invoiceNumber,
        });
        this.resetStore();
        this.$router.push("/accountreceivables/invoices");
      })
      .catch(() => {
        this.showNotifError("notif_reject_fail");
      })
      .finally(() => (this.loading.reject = false));
  }

  onDeselectDoc(id: string, source: "DO" | "SO"): void {
    const finderOptions =
      source === "DO" ? this.dataDoNumber : (this.dataSalesOrder as any[]);
    const opt = finderOptions.find(item => item.value === id);
    const { dataSourceDetailsTabpane: invoiceLines } = this;
    const newSource = invoiceLines.filter(row => {
      // side effect: push deleted line id
      if (row.id && row.documentReference === opt?.documentNumber) {
        this.listDeletedIdDetails.push(row.id);
      }

      return row.documentReference !== opt?.documentNumber ?? "";
    });

    this.setNumbering(newSource);
    this.dataSourceDetailsTabpane = newSource;
    this.commitStore();
    this.doPricingCalc();
  }

  setNumbering(source: TableDataDetails[]): void {
    source.forEach((data, index) => {
      data.key = index;
      data.no = `${index + 1}.`;
    });
  }

  handleDeleteRow(): void {
    this.dataSourceDetailsTabpane = this.dataSourceDetailsTabpane.filter(
      data => {
        if (this.selectedRowKeysDetails.includes(data.key) && data.id) {
          this.listDeletedIdDetails.push(data.id);
        }
        return !this.selectedRowKeysDetails.includes(data.key);
      }
    );
    this.setNumbering(this.dataSourceDetailsTabpane);
    this.dataSourceDetailsTabpane = this.dataSourceDetailsTabpane.slice();
    this.selectedRowKeysDetails = [];
    this.commitStore();
    this.doPricingCalc();
  }

  handlePostJournal(): void {
    this.loading.postJournal = true;
    invoiceServices
      .postJournal(this.docId)
      .then(response => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: response.journalNo,
        });
        this.$router.push("/accountreceivables/invoices");
      })
      .finally(() => {
        this.loading.postJournal = false;
      });
  }

  onChangeEmployee(e: Option<ListContactDataDto> | undefined): void {
    this.setForm({
      ...this.storeForm,
      assignee: e?.label || "",
    });
  }

  onChangeTaxCalculation(e: TAX_CALCULATION | undefined): void {
    this.form.setFieldsValue({ taxCalculation: e });
    this.setForm({
      ...this.storeForm,
      taxCalculation: (e || "") as TAX_CALCULATION,
    });

    if (this.isTaxNone) {
      this.disableTaxLines();
    } else {
      this.enableTaxLines();
    }

    this.doPricingCalc();
  }
}
