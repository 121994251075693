var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        { attrs: { span: 16 } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.total.label) } },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.total.decorator,
                    expression: "formRules.total.decorator"
                  }
                ],
                staticClass: "w-100",
                attrs: {
                  name: _vm.formRules.total.name,
                  placeholder: _vm.$t(_vm.formRules.total.placeholder),
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  autocomplete: "off",
                  disabled: true
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.prepaymentUsed.label) } },
            [
              _c(
                "a-space",
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.prepaymentUsed.decorator,
                        expression: "formRules.prepaymentUsed.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.prepaymentUsed.name,
                      placeholder: _vm.$t(
                        _vm.formRules.prepaymentUsed.placeholder
                      ),
                      autocomplete: "off",
                      disabled: true
                    }
                  }),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "dashed" },
                      on: {
                        click: function($event) {
                          return _vm.emitView("prepaymentUsed")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_more")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.remainingInvoiceAmount.label)
              }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.remainingInvoiceAmount.decorator,
                    expression: "formRules.remainingInvoiceAmount.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.remainingInvoiceAmount.name,
                  placeholder: _vm.$t(
                    _vm.formRules.remainingInvoiceAmount.placeholder
                  ),
                  autocomplete: "off",
                  disabled: true
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.creditMemo.label) } },
            [
              _c(
                "a-space",
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.creditMemo.decorator,
                        expression: "formRules.creditMemo.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.creditMemo.name,
                      placeholder: _vm.$t(_vm.formRules.creditMemo.placeholder),
                      autocomplete: "off",
                      disabled: true
                    }
                  }),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "dashed" },
                      on: {
                        click: function($event) {
                          return _vm.emitView("creditMemo")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_more")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.return.label) } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.return.decorator,
                    expression: "formRules.return.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.return.name,
                  placeholder: _vm.$t(_vm.formRules.return.placeholder),
                  autocomplete: "off",
                  disabled: true
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: { label: _vm.$t(_vm.formRules.accountReceivables.label) }
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.accountReceivables.decorator,
                    expression: "formRules.accountReceivables.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.accountReceivables.name,
                  placeholder: _vm.$t(
                    _vm.formRules.accountReceivables.placeholder
                  ),
                  autocomplete: "off",
                  disabled: true
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.paidStatus.label) } },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "dashed" },
                  on: {
                    click: function($event) {
                      return _vm.emitView("paidStatus")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_more")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.joinInvoice.label) } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.joinInvoice.decorator,
                    expression: "formRules.joinInvoice.decorator"
                  }
                ],
                attrs: {
                  name: _vm.formRules.joinInvoice.name,
                  placeholder: _vm.$t(_vm.formRules.joinInvoice.placeholder),
                  autocomplete: "off",
                  disabled: true
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }